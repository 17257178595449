@media (min-width: 480px) and (max-width: 575px) {
  .col-xs {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    max-width: 100% !important;
  }

  .col-xs-auto {
    -ms-flex: 0 0 auto !important;
    flex: 0 0 auto !important;
    width: auto !important;
    max-width: none !important;
  }

  .col-xs-1 {
    -ms-flex: 0 0 8.33333% !important;
    flex: 0 0 8.33333% !important;
    max-width: 8.33333% !important;
  }

  .col-xs-2 {
    -ms-flex: 0 0 16.66667% !important;
    flex: 0 0 16.66667% !important;
    max-width: 16.66667% !important;
  }

  .col-xs-3 {
    -ms-flex: 0 0 25% !important;
    flex: 0 0 25% !important;
    max-width: 25% !important;
  }

  .col-xs-4 {
    -ms-flex: 0 0 33.33333% !important;
    flex: 0 0 33.33333% !important;
    max-width: 33.33333% !important;
  }

  .col-xs-5 {
    -ms-flex: 0 0 41.66667% !important;
    flex: 0 0 41.66667% !important;
    max-width: 41.66667% !important;
  }

  .col-xs-6 {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }

  .col-xs-7 {
    -ms-flex: 0 0 58.33333% !important;
    flex: 0 0 58.33333% !important;
    max-width: 58.33333% !important;
  }

  .col-xs-8 {
    -ms-flex: 0 0 66.66667% !important;
    flex: 0 0 66.66667% !important;
    max-width: 66.66667% !important;
  }

  .col-xs-9 {
    -ms-flex: 0 0 75% !important;
    flex: 0 0 75% !important;
    max-width: 75% !important;
  }

  .col-xs-10 {
    -ms-flex: 0 0 83.33333% !important;
    flex: 0 0 83.33333% !important;
    max-width: 83.33333% !important;
  }

  .col-xs-11 {
    -ms-flex: 0 0 91.66667% !important;
    flex: 0 0 91.66667% !important;
    max-width: 91.66667% !important;
  }

  .col-xs-12 {
    -ms-flex: 0 0 100% !important;
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

@media (min-width: 480px) {
  .text-xs-left {
    text-align: left !important;
  }

  .text-xs-right {
    text-align: right !important;
  }
}

@media (max-width: 991px) {
  .rs-picker-menu {
    max-width: calc(100vw - 10%);
  }

  .location-icon img {
    height: 15px;
  }

  .card-body {
    padding: 1.25rem 2rem !important;
  }

  .select-document .card .card-body {
    padding: 12px !important;
  }

  .main-title {
    font-size: 21px;
  }

  .btn {
    font-size: 16px !important;
  }

  .selfverification-info {
    margin: 0 0 40px 0;
  }

  .label-form {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .rs-picker-toggle-value {
    line-height: 2.4;
  }

  .home-body-menu {
    padding: 15px;
  }

}

@media (max-width: 575px) {
  .tvchannelBody .no-data-found {
    height: calc(100vh - 300px);
  }

  .r-hotelcharges-left {
    font-weight: 500 !important;
  }

  .r-hotelcharges-right {
    font-weight: 600 !important;
  }

  .fw-700 {
    font-weight: 700 !important;
  }

  .hotelcharges-desc.desc-data {
    font-size: 11px;
    line-height: 1.7;
  }

  .home-menu-label-name {
    font-size: 10px;
  }

  .home-body-menu {
    padding: 5px 2px;
  }

  .loyalty-btn {
    font-size: 10px !important;
  }

  /* .loyalty-details h4 {
    font-size: 11px;
  } */

  /* .loyalty-desc {
    font-size: 10px;
  } */

  /* .loyalty-read-btn {
    font-size: 10px !important;
  } */

  /* .loyalty-sec-slider {
    height: 120px;
  } */
}

@media (max-width: 480px) {
  .wakeup-content {
    grid-template-columns: 80px 1fr 80px;
  }

  .wakeup-title {
    font-size: 14px;
  }

  .wakeup-label {
    font-size: 12px;
  }

  .wakeup-sub-text {
    font-size: 9px;
  }

  .home-body-imgs {
    height: 65px;
  }

  .wifi-device-box h1 {
    font-size: 16px;
  }

  .set-padding-doc {
    padding: 30px 0;
  }

  .id-verification-sec .document-sacn {
    margin-bottom: 30px;
  }

  .rs-picker-toggle-textbox::placeholder,
  .rs-picker-toggle-textbox,
  .rs-picker-toggle-value,
  .intl-tel-input.allow-dropdown .selected-flag {
    font-size: 14px;
  }

  .reservation-detail-title .sec-title {
    font-size: 13px;
  }
/* 
  .successfulcheckin-desc,
  .sec-title {
    font-size: 16px;
  } */

  input.form-control,
  textarea.form-control,
  .rs-calendar-header-title .rs-picker-select-menu-item,
  .reservation-details,
  .location span,
  .checkbox,
  .radio,
  .readyto-check-desc {
    font-size: 14px;
  }

  .reservation-sec .reservation-list li,
  .checkin-app-form .checkbox,
  .standardcheckin-title,
  .detailsabout-desc,
  .reservation-list li,
  .reservation-sec {
    font-size: 12px;
  }

  .standardcheckin-title p {
    font-size: 16px !important;
  }

  .review-hotelcharges span,
  .validunexpired-sec,
  .issuedid-sec,
  .inn-suites-desc {
    font-size: 14px;
  }

  .termsconditions-desc,
  .stay-apply,
  .desc-data {
    font-size: 13px;
  }

  .photoyourself-desc,
  .idverification-oneline {
    font-size: 16px;
  }

  .electronicsign-desc {
    font-size: 9px;
  }

  .terms-conditions-desc,
  .terms-conditions-title .main-title {
    font-size: 11px;
  }

  .selfverification-info {
    font-size: 13px;
    margin: 0 0 30px 0;
  }

  .time-info {
    font-size: 18px;
  }

  .creditard-desc,
  .stay-information-info .desc-data {
    font-size: 13px;
  }

  .label-form {
    font-size: 12px;
  }

  .terms-conditions-desc {
    font-weight: 500;
  }

  .checkbox .checkmark,
  .radio .checkmark {
    top: 0px;
    height: 16px;
    width: 16px;
  }

  .checkbox,
  .radio {
    padding-left: 22px !important;
  }

  .scan-card img {
    height: 16px;
  }

  ul.plusminus-list {
    margin-top: 3px;
  }

  .btn.plus-minus-icon {
    line-height: 25px !important;
    font-size: 20px !important;
  }

  .input-group-text-right {
    right: 0px;
  }

  .rs-calendar-table-cell {
    padding: 2px;
  }

  .rs-picker-check-menu {
    height: auto !important;
    max-height: 200px !important;
  }

  .rs-calendar-time-view {
    max-height: 200px;
    min-height: 220px;
  }

  .rs-calendar-table-header-row .rs-calendar-table-cell-content {
    font-size: 12px;
  }

  .rs-calendar-time-dropdown-cell {
    font-size: 12px;
  }

  .btn {
    padding: 15px 0 !important;
  }

  .btn-xs {
    padding: 8px !important;
    font-size: 12px !important;
    min-width: 100px;
  }

  .btn-sm-txt {
    font-size: 14px !important;
    line-height: 1.2 !important;
  }

  .id-verification-sec .select-document .card {
    margin: 0;
    width: 118px;
    height: 113px;
    align-items: center;
    flex-direction: inherit;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .select-document .card .card-body span {
    font-size: 12px;
    font-weight: 600;
    margin-top: 15px;
    display: block;
  }

  .idverification-imgs {
    height: 45px;
    margin-bottom: 5px;
  }

  .checkbox .checkmark:after,
  .radio .checkmark:after {
    height: 8px;
  }

  .visacard-img {
    height: 180px;
  }

  .electronicsign-title {
    font-weight: 500;
    font-size: 14px;
  }

  .electronicsign-desc {
    font-size: 10px;
  }


  .housekeeping-img {
    height: 60px;
    width: 80px;
  }

}

@media (max-width: 375px) {
  .tvchannelBody .no-data-found img {
    width: 200px;
    height: 200px;
  }

  .successfulcheckin-desc p {
    font-size: 14px;
  }

  .wakeup-content {
    grid-template-columns: 60px 1fr 60px;
  }

  .amenitiesBox .card-stay-imgs {
    height: 80px;
  }

  .home-body-imgs {
    height: 50px;
  }

  .tableContent,
  .tableHeader {
    font-size: 12px;
  }

  /* .notificationContent {
    flex-basis: 300px;
  } */

  img.nearby-img {
    width: 40px;
    height: 40px;
  }

  .housekeeping-img {
    height: 60px;
    width: 80px;
  }

  .images-block {
    grid-template-columns: auto auto auto auto;
    gap: 10px;
  }

  .welcome-title h1 {
    font-size: 26px;
    margin-top: 20px;
  }

  .welcome-img-logo {
    height: 80px;
    margin: 20px 0;
  }

  .welcome-text {
    font-size: 16px;
    margin: 0 0;
  }

  .welcome-img {
    margin: 30px 0 40px;
    height: 210px;
  }

  .card-stay-imgs {
    height: 45px;
  }
}

@media (max-width: 374px) {
  .housekeeping-img {
    height: 50px;
    width: 60px;
  }

  .device-images {
    height: 63px;
    width: 63px;
  }

  .device-name p {
    width: 63px;
  }

  .wifi-device-box {
    padding: 15px 10px;
  }

  .device-images img {
    padding: 5px;
  }

  .wifi-device-box h1 {
    font-size: 14px;
  }

  .successfulcheckin-desc,
  .sec-title {
    font-size: 14px;
  }

  .product-name {
    max-width: 50px;
  }

  .category-image {
    height: 80px;
  }

  .chat-bot-bottom-button-inner {
    gap: 5px;
  }

  .chat-bot-bottom-button-inner button {
    font-size: 11px;
    padding: 2px 6px;
  }
  .label-name,
  .home-menu-label-name,
  .label-name-stay {
    font-size: 11px;
  }

}

@media (max-height: 740px) and (min-height: 700px) {
  .welcome-title h1 {
    font-size: 32px;
    margin-top: 30px;
  }

  .welcome-img-logo {
    height: 90px;
    margin: 30px 0;
  }

  .welcome-text {
    font-size: 18px;
    margin: 20px 0;
  }

  .welcome-img {
    margin: 40px 0 40px;
    height: 230px;
  }
}

@media (max-width: 360px) {
  .wakeup-sub-text span {
    padding: 0px 1px;
  }

  .wakeup-content {
    grid-template-columns: 50px 1fr 50px;
  }

  .wakeup-title {
    font-size: 12px;
  }

  .wakeup-label {
    font-size: 10px;
  }

  .wakeup-sub-text {
    font-size: 8px;
  }

  .home-header-left {
    width: 60% !important;
  }

  .tap-to-locate-block {
    width: 40% !important;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 480px;
  }

  .footer {
    max-width: 480px;
  }

  .header {
    max-width: 480px;
  }

  .body-content {
    max-width: 480px;
  }

  .top-bar-chat {
    max-width: 480px;
  }

  .chat-bottom-msg {
    max-width: 480px;
  }

  .tv-grid-height {
    max-width: 480px;
  }

  .edit-icon-request {
    max-width: 480px;
  }

  .pwa-popup {
    max-width: 470px;
  }

  .new-block-file .chat-img {
    width: 115px;
    height: 115px;
  }

  .home-header-left {
    width: 70%;
  }

  .tap-to-locate-block {
    width: 30%;
  }
}

@media (max-width: 768px) {
  .guest-chat-gallery-view {
    grid-template-columns: 1fr 1fr 1fr;
  }

  /* .chat-search-form .search-expanded {
    max-width: max-content;
    width: -webkit-fill-available;
  } */
}

@media (max-width: 767px) {
  .container {
    width: 100%;
    max-width: 100%;
  }

  .home-header-left {
    width: 70%;
  }

  .tap-to-locate-block {
    width: 30%;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 540px;
  }

  .footer {
    max-width: 540px;
  }

  .header {
    max-width: 540px;
  }

  .body-content {
    max-width: 540px;
  }

  .top-bar-chat {
    max-width: 540px;
  }

  .chat-bottom-msg {
    max-width: 540px;
  }

  .tv-grid-height {
    max-width: 540px;
  }

  .edit-icon-request {
    max-width: 540px;
  }

  .pwa-popup {
    max-width: 530px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 570px;
  }

  .header {
    max-width: 570px;
  }

  .footer {
    max-width: 570px;
  }

  .body-content {
    max-width: 570px;
  }

  .top-bar-chat {
    max-width: 570px;
  }

  .chat-bottom-msg {
    max-width: 570px;
  }

  .tv-grid-height {
    max-width: 570px;
  }

  .edit-icon-request {
    max-width: 570px;
  }

  .pwa-popup {
    max-width: 560px;
  }
}

@media (max-width: 425px) {
  .chat-container .chat-audio {
    max-width: 100%;
  }

  .chat-container audio {
    width: 100%;
  }

  .chat-img-block audio {
    width: 500px;
  }
}

/* @media (max-width: 412px) {
  .chat-search-form .search-expanded {
    min-width: 250px;
  }
}

@media (max-width: 393px) {
  .chat-search-form .search-expanded {
    min-width: 230px;
  }
}

@media (max-width: 390px) {
  .chat-search-form .search-expanded {
    min-width: 225px;
  }
}

@media (max-width: 375px) {
  .chat-search-form .search-expanded {
    min-width: 210px;
  }
}

@media (max-width: 360px) {
  .chat-search-form .search-expanded {
    min-width: 195px;
  }
} */

@media (max-width: 320px) {

  .chat-item.right .w-90,
  .chat-item.left .w-90 {
    width: 100%;
  }

  .chat-img-block audio {
    width: 400px;
  }

  /* .chat-search-form .search-expanded {
    min-width: 155px;
  } */
}