body {
  background-color: #f5f9ff !important;
  font-family: "Poppins", sans-serif;
}

a {
  color: var(--lightblue) !important;
}

.section-space {
  padding-top: 15px;
  padding-bottom: 15px;
}

.text-gray {
  color: var(--darkgray);
}

.location span {
  font-size: 16px;
}

.form-group {
  margin-bottom: 15px !important;
}

.form-control {
  color: var(--darkblue) !important;
}

.reservation-sec ul.reservation-list {
  padding: 0;
}

.readyto-check-title {
  margin: 0 0 5px 0;
}

.text-blue {
  color: var(--darkblue);
}

.main-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.reservation-details {
  margin: 10px 0 0 0;
}

.reservation-sec {
  margin: 0 0 15px 0;
}

.foundreservation .reservation-sec {
  margin: 0 0 0 0;
}

.document-sacn {
  margin: 0 0 20px 0;
}

.reservation-sec .reservation-list {
  list-style: none;
  margin: 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

ul.selfverification-list {
  margin-bottom: 0;
}

.reservation-sec .reservation-list li {
  font-size: 16px;
  font-weight: 500;
}

input.form-control,
textarea.form-control {
  min-height: 45px !important;
  /*55,charmi(23-02-2023)*/
  border-radius: 10px !important;
  padding: 10px 15px !important;
  font-size: 14px !important;
  background-color: var(--white);
  border: 1px solid var(--lightgray-700);
}

input.form-control:hover {
  border-color: var(--lightblue-200);
}

textarea.form-control:hover {
  border-color: var(--lightblue-200);
}

.form-control {
  font-weight: 500 !important;
}

.form-control::-webkit-input-placeholder {
  color: #97acc7 !important;
  font-weight: 400 !important;
}

.form-control::-moz-placeholder {
  color: #97acc7 !important;
  font-weight: 400 !important;
}

.form-control:-ms-input-placeholder {
  color: #97acc7 !important;
  font-weight: 400 !important;
}

.form-control::-ms-input-placeholder {
  color: #97acc7 !important;
  font-weight: 400 !important;
}

.form-control::placeholder {
  color: #97acc7 !important;
  font-weight: 400 !important;
}

.form-control:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  outline: none !important;
  border: 1px solid #bec5d1 !important;
}

.form-or {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  color: #22317c;
  line-height: 1.5;
  background-color: #f2f2f2;
  border: 2px solid #ebecf0;
}

.form-or p {
  margin: 0;
  padding: 4px;
  font-size: 13px;
  text-align: center;
}

.btn-loader {
  border: 3px solid var(--white);
  /* Light grey */
  border-top: 3px solid var(--orange);
  /* Blue */
  border-radius: 50%;
  -webkit-animation: spin 0.8s linear infinite;
  animation: spin 0.8s linear infinite;
}

.btn-loader.sm {
  width: 16px;
  height: 16px;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.btn {
  background-color: #22317c !important;
  width: 100% !important;
  color: var(--white) !important;
  line-height: 1 !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 15px 20px !important;
  border: none !important;
  display: block;
}

.btn-area {
  background-color: #22317c !important;
  width: 367px !important;
  color: var(--white) !important;
  line-height: 1 !important;
  border-radius: 10px !important;
  font-size: 18px !important;
  font-weight: 500 !important;
  padding: 15px 20px !important;
  border: none !important;
}

.btn-sm-txt {
  font-size: 16px;
}

.btn.disabled,
.btn:disabled {
  color: #7e97b8 !important;
  opacity: 1 !important;
}

.text-primary {
  color: #2196f3 !important;
}

.btn.focus,
.btn:focus {
  outline: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rs-picker-date .rs-picker-toggle input,
.rs-picker-daterange .rs-picker-toggle input {
  padding: 10px 20px;
}

.rs-picker-toggle-placeholder {
  color: #97acc7 !important;
  font-size: 14px;
  line-height: 1.5;
  /*2.5,charmi(23-02-2023)*/
}

.rs-picker-toggle.rs-picker-toggle-active.rs-picker-toggle-placeholder {
  color: #c7b397 !important;
  font-size: 1rem;
}

.rs-picker {
  border-color: transparent !important;
  height: calc(2.5em + 7px) !important;
  border-radius: 10px !important;
  padding: 0;
  /*srushti 3-4-23 for latecheckout*/
}

.rs-picker:hover {
  border-color: 1px solid #ced4da;
}

.rs-picker .rs-btn,
.rs-input {
  cursor: text;
  height: calc(2.5em + 10px) !important;
  padding: 10px 15px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: var(--white);
}

.form-group-sm .rs-input,
.form-group-sm .rs-picker .rs-btn {
  padding: 5px 8px;
  min-height: 30px;
}

.rs-picker-menu.rs-picker-select-menu,
.rs-picker-menu.rs-picker-check-menu {
  padding: 6px 0 6px 0;
}

.rs-picker-select-menu-items {
  max-height: 230px !important;
}

.rs-picker-date-menu {
  max-width: unset;
}

.rs-picker-cleanable.rs-picker-has-value .rs-picker-toggle {
  padding-right: 44px;
}

.rs-picker-default .rs-picker-toggle,
.rs-input {
  text-align: left;
}

.rs-picker-toggle,
.rs-input {
  width: 100%;
}

.rs-picker-toggle-read-only {
  opacity: 0;
  pointer-events: none;
}

.rs-picker-toggle-textbox {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border: none;
  color: var(--darkblue) !important;
  border-radius: 10px !important;
  font-size: 1rem;
  line-height: 2.5;
  font-weight: 400;
}

.rs-picker-toggle-textbox::-webkit-input-placeholder {
  color: #97acc7 !important;
  font-size: 1rem;
}

.rs-picker-toggle-textbox::-moz-placeholder {
  color: #97acc7 !important;
  font-size: 1rem;
}

.rs-picker-toggle-textbox:-ms-input-placeholder {
  color: #97acc7 !important;
  font-size: 1rem;
}

.rs-picker-toggle-textbox::-ms-input-placeholder {
  color: #97acc7 !important;
  font-size: 1rem;
}

.rs-picker-toggle-textbox::placeholder {
  color: #97acc7 !important;
  font-size: 1rem;
}

.rs-picker-toggle-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 95%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--darkblue) !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5 !important;
  /* 2.1 => charmi(27-02-2023)*/
}

.rs-picker-toggle-clean {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 17px;
  right: 40px;
  height: 20px;
  font-size: 12px;
  background: inherit;
  color: #007bff;
  stroke: #007bff;
  -webkit-transition: 0.2s color linear;
  -o-transition: 0.2s color linear;
  transition: 0.2s color linear;
  cursor: pointer;
}

.rs-btn-close .rs-icon {
  vertical-align: bottom;
}

.rs-calendar {
  padding-top: 12px;
  position: relative;
  overflow: hidden;
}

.rs-picker-menu .rs-calendar {
  min-width: 246px;
  display: block;
  margin: 0 auto;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time) {
  margin: 0 auto;
  padding-left: 12px;
  padding-right: 12px;
}

.rs-picker-menu .rs-calendar-header {
  width: 100%;
  text-align: center;
}

.rs-modal .rs-modal-dialog {
  height: 100%;
}

.rs-modal-body {
  position: relative;
  /* max-height: calc(100vh - 150px) !important; */
  /* max-height: calc(100vh - 80px) !important; */
  /* height: calc(100vh - 200px) !important; */
  max-height: calc(100vh - 200px) !important;
  /* max-height: fit-content !important; */
  padding-bottom: 15px;
  padding-top: 15px;
  margin-top: 0px;
  height: 100%;
}

.rs-modal-xl .rs-modal-body {
  height: 100%;
  max-height: 100% !important;
}

.rs-modal-xl .rs-modal-body.has-footer {
  max-height: calc(100% - 80px) !important;
}

.rs-modal-xl .rs-modal-header.diff-modal-title ~ .rs-modal-body {
  height: calc(100% - 35px) !important;
  max-height: 100% !important;
}

.rs-calendar-header:before,
.rs-calendar-header:after {
  content: " ";
  display: table;
}

.rs-calendar-header-month-toolbar {
  display: inline-block;
}

.rs-calendar-header-time-toolbar {
  display: inline-block;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar {
  display: block;
  text-align: center;
  width: 100%;
}

.rs-btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1.4;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  cursor: pointer;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  outline: none;
}

.rs-btn-subtle {
  color: #007bff !important;
  background-color: #ffff !important;
  border: none;
}

.rs-btn-xs {
  font-size: 16px;
  line-height: 1.4;
  padding: 2px 8px;
}

.rs-btn-icon.rs-btn-xs {
  padding: 4px;
  line-height: 12px;
}

.rs-btn-subtle.rs-btn-icon {
  border: 1px solid #97acc7;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-backward {
  float: left;
}

.rs-btn-icon.rs-btn-xs > .rs-icon {
  font-size: 12px;
}

.rs-btn-icon > .rs-icon {
  vertical-align: bottom;
}

svg:not(:root) {
  overflow: hidden;
}

.rs-ripple-pond {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.rs-ripple {
  position: absolute;
  display: block;
  border-radius: 50%;
  background-color: transparent;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}

.rs-calendar-header-title {
  margin: 0 4px;
  background: transparent !important;
  color: #007bff !important;
  font-weight: 600;
  font-size: 16px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-forward {
  float: right;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar:before,
.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar:after {
  content: " ";
  display: table;
}

.rs-calendar-header-has-month:not(.rs-calendar-header-has-time)
  .rs-calendar-header-month-toolbar:after {
  clear: both;
}

.rs-calendar-header:after {
  clear: both;
}

.rs-calendar-view {
  padding: 4px 12px 12px;
}

.rs-calendar-table {
  display: table;
  table-layout: fixed;
}

.rs-calendar-table-row {
  display: table-row;
}

.rs-calendar-table-cell {
  display: table-cell;
  width: 1%;
  padding: 6px;
  text-align: center;
  vertical-align: middle;
}

.rs-calendar-table-cell.rs-calendar-table-cell-un-same-month {
  opacity: 0.4;
}

.rs-calendar-table-row .rs-calendar-table-cell:first-child,
.rs-calendar-table-row .rs-calendar-table-cell:last-child {
  color: #007bff;
}

.rs-calendar-table-cell-content {
  font-size: 14px;
  line-height: 1.4;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content {
  color: #007bff;
  font-size: 14px;
  line-height: 1.6;
  padding-top: 2px;
  padding-bottom: 2px;
  font-weight: 600;
  border: 1px solid transparent;
}

.rs-calendar-table-header-row .rs-calendar-table-cell-content,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:focus,
.rs-calendar-table-header-row .rs-calendar-table-cell-content:hover:focus {
  background: none;
  cursor: auto;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  display: inline-block;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-header-row
  .rs-calendar-table-cell-content {
  height: 26px;
  padding-top: 0;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
  color: #fff;
  background-color: #007bff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background-color: #007bff;
  color: #ffff;
}

.rs-calendar-table-row.rs-calendar-table-header-row
  .rs-calendar-table-cell:hover
  .rs-calendar-table-cell-content {
  background: transparent;
  color: #007bff;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-table-cell:hover.rs-calendar-table-cell-selected
  .rs-calendar-table-cell-content {
  border: 1px solid #007bff;
}

.rs-btn-link {
  color: #007bff;
  background-color: transparent;
  border: none;
}

.rs-picker-toolbar-right {
  float: right;
}

.rs-btn-primary {
  color: #fff;
  background-color: #97acc7;
  border: none;
}

.rs-calendar-month-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  border-top: 1px solid #97acc7;
  margin-top: 0;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  z-index: 1;
}

.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown {
  display: block;
}

.rs-calendar-show-month-dropdown .rs-calendar-month-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
  animation: 0.3s linear slideDown;
}

.rs-calendar-month-dropdown-scroll {
  height: 230px;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-scroll {
  height: 285px;
}

.rs-calendar-month-dropdown-row {
  position: relative;
  padding-left: 52px;
  padding-right: 12px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.rs-calendar-month-dropdown-row:not(:last-child) {
  border-bottom: 1px dotted #97acc7;
}

.rs-calendar-month-dropdown-year {
  position: absolute;
  top: calc(50% - 0.8em);
  left: 12px;
  font-weight: 600;
  color: #007bff;
}

.rs-calendar-month-dropdown-list {
  display: block;
}

.rs-calendar-month-dropdown-cell {
  display: inline-block;
  width: calc((100% - 1px * 12) / 6);
  margin: 1px;
  text-align: center;
  vertical-align: middle;
}

.rs-calendar-month-dropdown-cell-content {
  display: inline-block;
  font-size: 14px;
  line-height: 1.4;
  padding: 5px;
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid transparent;
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content,
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  width: 30px;
  height: 30px;
}

.rs-calendar-month-dropdown-list:before,
.rs-calendar-month-dropdown-list:after {
  content: " ";
  display: table;
}

.rs-calendar-month-dropdown-year-active {
  color: var(--darkblue);
}

.rs-picker-menu .rs-calendar .rs-calendar-month-dropdown-cell-content:hover {
  background-color: #007bff;
  color: #fff;
}

.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  color: #fff;
  background-color: #007bff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.rs-picker-menu
  .rs-calendar
  .rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content:hover {
  border: 1px solid #97acc7;
}

.rs-picker-daterange-menu.rs-picker-menu {
  width: auto;
}

.rs-picker-daterange-header {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-bottom: 1px solid #97acc7;
}

.rs-picker-daterange-calendar-group {
  min-width: 492px;
}

.rs-picker-daterange-menu .rs-calendar {
  display: inline-block;
  height: 278px;
  padding-bottom: 12px;
  border-bottom: 1px solid #97acc7;
}

.rs-picker-daterange-menu .rs-calendar:first-child {
  border-right: 1px solid #97acc7;
}

.rs-calendar-table-cell-selected,
.rs-calendar-table-cell-in-range {
  position: relative;
}

.rs-calendar-table-cell-selected:before,
.rs-calendar-table-cell-in-range:before {
  content: "";
  display: block;
  width: 100%;
  margin-top: 4px;
  height: 34px;
  position: absolute;
  z-index: 0;
  top: 0;
}

.rs-calendar-table-cell-in-range:before {
  /* background-color: #000; */
  background-color: #f5f7f9;
  color: #007bff;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-table-cell-in-range .rs-calendar-table-cell-content {
  z-index: 1;
  position: relative;
}

.rs-calendar-header-has-time:not(.rs-calendar-header-has-month) {
  text-align: center;
}

.rs-calendar-time-dropdown {
  display: none;
  position: absolute;
  top: 44px;
  padding-left: 12px;
  padding-right: 12px;
  width: 100%;
  background-color: #fff;
  color: #007bff;
  overflow: hidden;
  z-index: 1;
}

.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown {
  display: block;
}

.rs-calendar-show-time-dropdown .rs-calendar-time-dropdown-content {
  -webkit-animation: 0.3s linear slideDown;
  animation: 0.3s linear slideDown;
}

@-webkit-keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.rs-calendar-time-dropdown-row {
  display: table;
  width: 100%;
}

.rs-calendar-time-dropdown-column {
  display: table-cell;
  width: 1%;
}

.rs-calendar-time-dropdown-column-title {
  width: 100%;
  background-color: #e5edf5;
  color: #007bff;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  padding-top: 2px;
  padding-bottom: 2px;
}

.rs-calendar-time-dropdown-column > ul,
.rs-calendar-time-dropdown-column > ul > li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.rs-calendar-time-dropdown-column > ul {
  height: 250px;
  overflow-y: auto;
  padding-bottom: 30px;
}

.rs-calendar-time-dropdown-cell {
  font-size: 14px;
  line-height: 20px;
  color: #007bff;
  padding: 5px;
  display: block;
  text-align: center;
  cursor: pointer;
  border: 1px solid transparent;
}

.rs-calendar-time-dropdown-cell:focus,
.rs-calendar-time-dropdown-cell:focus-within,
.rs-calendar-time-dropdown-cell:focus-visible,
.rs-calendar-time-dropdown-cell:hover {
  background-color: #007bff;
  color: #fff !important;
  text-decoration: none !important;
}

.rs-calendar-time-dropdown-cell.rs-calendar-time-dropdown-cell-active {
  background-color: #007bff;
  color: #fff !important;
}

.rs-calendar-time-dropdown-cell:hover.rs-calendar-time-dropdown-cell-active {
  border-color: #97acc7;
}

.rs-row::before,
.rs-row::after {
  content: " ";
  display: table;
}

.rs-col {
  float: left;
  position: relative;
  min-height: 1px;
  padding-left: 5px;
  padding-right: 5px;
}

.rs-input {
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  border: 1px solid transparent;
  border-radius: 4px;
  white-space: nowrap;
  vertical-align: middle;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 1.4;
  overflow: hidden;
  text-decoration: none;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  cursor: text;
  background: var(--white);
  color: var(--darkblue);
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -o-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: color 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    -webkit-box-shadow 0.15s ease-in-out;
}

.rs-input:focus-visible {
  outline: none !important;
}

.rs-picker-toggle-textbox:focus-visible {
  outline: none !important;
}

.rs-picker-toggle-caret {
  display: -ms-flexbox;
  display: -webkit-box;
  display: flex;
  -ms-flex-align: center;
  -webkit-box-align: center;
  align-items: center;
  margin-left: 2px;
  position: absolute;
  top: 17px;
  right: 12px;
  height: 20px;
  font-size: 16px;
  color: var(--darkblue);
  padding-left: 5px;
}

.rs-picker-toggle-textbox::-webkit-input-placeholder {
  color: var(--placeholder-color);
}

.rs-picker-toggle-textbox::-moz-placeholder {
  color: var(--placeholder-color);
}

.rs-picker-toggle-textbox:-ms-input-placeholder {
  color: var(--placeholder-color);
}

.rs-picker-toggle-textbox::-ms-input-placeholder {
  color: var(--placeholder-color);
}

.rs-picker-toggle-textbox::placeholder {
  color: var(--placeholder-color);
}

.checkbox,
.radio {
  display: block;
  position: relative;
  padding-left: 30px !important;
  margin-bottom: 40px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  min-height: 16px;
  line-height: 1.3;
}

.checkbox input,
.radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox .checkmark,
.radio .checkmark,
.checkmark-manual {
  position: absolute;
  top: -2px;
  left: 0;
  height: 22px;
  width: 22px;
  border-radius: 4px;
  border: 1px solid #7e97b8;
  z-index: 1;
}

.checkbox .checkmark:after,
.radio .checkmark:after,
.checkmark-manual:after {
  content: "";
  position: absolute;
  display: none;
  left: 0;
  top: 0;
  right: 0;
  width: 5px;
  bottom: 0px;
  margin: auto;
  height: 11px;
  border: solid var(--darkblue);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox .checkmark:after,
.checkmark-manual:after {
  bottom: 2px;
}

.id-verification-sec .card {
  margin: 20px 20px 20px 20px;
}

.checkbox input:checked ~ .checkmark:after,
.radio input:checked ~ .checkmark:after,
.checkbox input:checked ~ .checkmark-manual:after {
  display: block;
}

.btn {
  color: var(--white) !important;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.card {
  border: 1px solid #c5e2ff !important;
  background-color: #f3f9ff !important;
  border-radius: 10px !important;
  margin: 0 0 20px 0 !important;
}

.card-body {
  padding: 1.25rem 3rem !important;
}

.sec-title {
  font-size: 18px;
  font-weight: 600;
  /*charmi(04-04-2023) (600)*/
  color: var(--darkblue);
  line-height: 1.4;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.foundreservation .card-body {
  padding: 1.25rem !important;
}

.stay-apply p {
  margin-bottom: 15px !important;
}

.btn-icon {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.btn .right-arrow-icone {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1);
}

.right-arrow-icone {
  display: block;
}

.btn.back-btn .right-arrow-icone img {
  position: relative;
  top: auto;
  bottom: 2px;
  height: 13px;
}

.modalContentPad .rs-modal-body {
  padding-bottom: 10px;
}

hr {
  border-top: 2px solid rgb(173 191 212) !important;
}

.fw-700 {
  font-weight: 700;
}

.intl-tel-input {
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type="text"],
.intl-tel-input.allow-dropdown input[type="tel"] {
  padding-right: 20px !important;
  padding-left: 20px !important;
  margin-left: 135px !important;
  display: inline-block;
}

.intl-tel-input input,
.intl-tel-input input[type="text"],
.intl-tel-input input[type="tel"] {
  position: relative;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-right: 36px;
  margin-right: 0;
}

.intl-tel-input.allow-dropdown .selected-flag {
  width: 80px;
  border: 1px solid #bec5d1 !important;
  background-color: var(--white) !important;
  height: 45px !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
}

.intl-tel-input .country-list {
  position: absolute;
  z-index: 2;
  list-style: none;
  text-align: left;
  padding: 0;
  margin: 0 0 0 -1px;
  -webkit-box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
  box-shadow: 1px 1px 4px rgb(0 0 0 / 20%);
  background-color: white;
  border: 1px solid #ccc;
  white-space: nowrap;
  max-height: 200px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.intl-tel-input .country-list .country.highlight {
  background-color: rgba(0, 0, 0, 0.05);
}

.back-btn-sec {
  background-color: #fff !important;
  width: 100% !important;
  color: #97acc7 !important;
  line-height: 1.4 !important;
  border-radius: 10px !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 15px 20px !important;
  -webkit-box-shadow: #ccdff4 -6px -6px 13px -7px;
  box-shadow: #ccdff4 -6px -6px 13px -7px;
  border: none !important;
  border-radius: 0px !important;
  background: #22317c !important;
  color: #ffff !important;
}

.stayinformation-sec .standardcheckin-sec .card {
  background-color: var(--white) !important;
  -webkit-box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 10%);
  box-shadow: 0px 0px 12px 1px rgb(0 0 0 / 10%);
  border: transparent !important;
}

.back-btn-sec a {
  color: #ffff !important;
  text-decoration: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.back-btn-sec a .right-arrow-icone img {
  -webkit-filter: brightness(0) invert(1) !important;
  filter: brightness(0) invert(1) !important;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
}

.lightblue {
  color: #2196f3;
}

.rs-picker-select-menu-item {
  padding: 8px 20px;
  display: block;
  font-weight: 500;
  color: var(--darkblue);
  cursor: pointer;
  font-size: 13px;
}

.rs-picker-none {
  padding: 5px 20px;
  display: block;
  font-weight: 500;
  color: var(--darkblue);
  cursor: not-allowed;
}

.rs-picker-select-menu-item:hover {
  color: #2196f3;
}

.stayinformation-sec .rs-picker-toggle-value {
  color: var(--darkblue) !important;
  text-transform: none;
}

.input-group-text-right {
  left: auto !important;
  right: 0px;
  top: 0;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active,
.rs-picker-select-menu-item.rs-picker-select-menu-item-focus {
  color: #2196f3;
}

.electronic-signature .card-body {
  padding: 1.25rem !important;
}

.electronicsign-area {
  min-height: 250px;
}

.submit-btn {
  font-weight: 600;
  color: #2095f3;
}

p:last-child {
  margin-bottom: 0;
}

.electronicsign-title {
  font-size: 14px;
  color: var(--lightgray-300);
}

.electronicsign-desc {
  color: var(--darkgray);
  font-size: 12px;
}

.electronic-signature {
  position: relative;
  display: block;
  text-align: center;
  margin: auto;
}

.electronic-signature img {
  display: block;
  margin: auto;
  height: 95%;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
  display: table-cell !important;
  vertical-align: baseline !important;
  padding-left: 5px !important;
  font-weight: 500 !important;
  color: var(--darkblue) !important;
}

.intl-tel-input .flag-container .arrow {
  display: inline-block !important;
  vertical-align: middle;
}

.intl-tel-input .selected-flag {
  line-height: 1.5;
}

.redirect-section {
  position: sticky;
  top: 0;
  z-index: 2;
}

.fw-700 {
  font-weight: 700 !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  scrollbar-width: thin;
  scrollbar-color: var(--gray-400) var(--gray-100);
}

::-webkit-scrollbar-track {
  background-color: var(--gray-100);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: var(--gray-400);
}

.validate-input.error .label-form-control {
  color: #d30808;
}

.validate-input.error input.form-control::-webkit-input-placeholder {
  color: #d30808 !important;
  border-color: #d30808 !important;
}

.validate-input.error input.form-control::-moz-placeholder {
  color: #d30808 !important;
  border-color: #d30808 !important;
}

.validate-input.error input.form-control:-ms-input-placeholder {
  color: #d30808 !important;
  border-color: #d30808 !important;
}

.validate-input.error input.form-control::-ms-input-placeholder {
  color: #d30808 !important;
  border-color: #d30808 !important;
}

.validate-input.error .form-control.rs-auto-complete,
.validate-input.error .form-control.rs-picker,
.validate-input.error textarea.form-control,
.validate-input.error input.form-control,
.validate-input.error input.form-control::placeholder {
  color: #d30808 !important;
  border-color: #d30808 !important;
}

.validate-input.error .form-control.rs-auto-complete:hover,
.validate-input.error .form-control.rs-auto-complete:focus,
.validate-input.error .form-control.rs-auto-complete:focus-within,
.validate-input.error .form-control.rs-picker:hover,
.validate-input.error .form-control.rs-picker:focus,
.validate-input.error .form-control.rs-picker:focus-within,
.validate-input.error textarea.form-control:hover,
.validate-input.error textarea.form-control:focus,
.validate-input.error textarea.form-control:focus-within,
.validate-input.error input.form-control:hover,
.validate-input.error input.form-control:focus,
.validate-input.error input.form-control:focus-within {
  border-color: #d30808 !important;
}

.error404 {
  height: calc(100vh - 170px);
}

.error-content {
  text-align: center;
}

.error-content .mini-text {
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 0;
}

.error-content .error-img {
  max-width: 500px;
}

.error-content .error-text {
  font-size: 18px;
  color: var(--dark-800);
  font-weight: 600;
}

.error .error {
  font-size: 13px;
  font-style: italic;
}

/* Error Style End */

/* NEW CHANGE START */
.btn-primary-dark {
  color: #fff !important;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
  width: 120px !important;
}

textarea.form-control {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.rs-picker-menu .rs-picker-search-bar {
  position: relative;
  padding: 6px 12px 12px;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-input {
  display: block;
  width: 100%;
  color: #003971;
  background-color: #e5edf5;
  background-image: none;
  border: 1px solid transparent;
  -webkit-transition: border-color ease-in-out 0.3s,
    background-color ease-in-out 0.3s;
  -o-transition: border-color ease-in-out 0.3s,
    background-color ease-in-out 0.3s;
  -webkit-transition: border-color ease-in-out 0.3s,
    background-color ease-in-out 0.3s;
  -o-transition: border-color ease-in-out 0.3s,
    background-color ease-in-out 0.3s;
  transition: border-color ease-in-out 0.3s, background-color ease-in-out 0.3s;
  border-radius: 4px;
  padding: 7px 11px;
  font-size: 14px;
  line-height: 1.42857143;
  min-width: 200px;
  padding-right: 36px;
  font-weight: 500;
}

.rs-picker-menu .rs-picker-search-bar .rs-picker-search-bar-search-icon {
  position: absolute;
  width: 14px;
  color: var(--secondary);
  font-size: 14px;
  height: 20px;
  top: 14px;
  right: 24px;
}

input.rs-picker-search-bar-input:focus-within {
  outline: none;
}

.preview-img {
  position: relative;
  display: block;
  border: 2px solid #fff;
  border-radius: 6px;
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  overflow: hidden;
  margin: 15px auto;
}

.preview-img img {
  position: relative;
  height: 100%;
  width: 100%;
  display: block;
}

.sample-img {
  -webkit-column-count: 1;
  -moz-column-count: 1;
  column-count: 1;
  border: 2px solid #fff;
  margin: 0px auto;
  border-radius: 7px;
  overflow: hidden;
}

.sample-img img {
  width: 100%;
  height: 100%;
}

.textarea-input input {
  border-radius: 10px;
  font-weight: 500;
  text-align: left;
}

/* NEW CHANGE END */

.stayinformation-sec .submit-btn-sec .btn.plus-minus-icon {
  background-color: #f3f9ff !important;
  border: 1px solid #d4e7fb !important;
}

.stayinformation-sec .set-carbtn .submit-btn-sec.addremovebtn .btn.submit-btn,
.stayinformation-sec .set-clearbtn a,
.submit-btn-sec.set-clearbtn .submit-btn {
  border: 1px solid rgba(112, 112, 112, 0.2) !important;
  color: #757575 !important;
  background-color: #f5f7f9 !important;
  height: 45px;
  width: 100% !important;
  border-radius: 8px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 15px 20px !important;
  border: none !important;
}

.stayinformation-sec .hr-line hr {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid rgba(126, 151, 184, 0.25) !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

* {
  padding: 0;
  margin: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.set-data-id-guest {
  -webkit-box-pack: left;
  -ms-flex-pack: left;
  justify-content: left;
}

.select-document-home .card-home .card-home-body {
  text-align: center;
}

.box-space {
  padding: 8px;
}

.label-name {
  font-size: 12px;
  font-weight: 600;
}

.weatherbox {
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
  margin-top: 0px;
  border-radius: 10px !important;
  overflow: hidden !important;
  margin: 5px;
}

.weatherbox .weatherwidget-io {
  border-radius: 10px !important;
  overflow: hidden !important;
}

iframe#weatherwidget-io-0 {
  border-radius: 10px !important;
  overflow: hidden !important;
}

.customer-name {
  font-weight: 500;
  word-wrap: break-word;
  font-size: 18px;
  line-height: 18px;
}

.customer-hello {
  font-weight: 400;
  font-size: 18px;
}

.customer-checkout {
  font-weight: 300;
  font-size: 14px;
}

.checkout-email {
  font-size: 16px;
  font-weight: 600;
  color: var(--darkgray-100);
}

.tap-to-locate {
  width: 20%;
  height: 60px;
  background: var(--darkblue-100);
  border-radius: 5px !important;
  -webkit-box-shadow: var(--lightblue-100) 3px 3px 17px 3px;
  box-shadow: var(--lightblue-100) 3px 3px 17px 3px;
  text-align: right;
  position: absolute;
  top: -155px;
  right: 60px;
}

.room-no {
  position: absolute;
  bottom: 0;
  right: 20px;
  top: 0;
  font-size: 32px;
  font-weight: 600;
}

.text-light-blue {
  color: var(--lightblue);
}

.title-foundsec p {
  font-size: 14px;
  color: var(--gray);
  font-weight: 500;
}

.wifi-pass-box {
  width: 100%;
  height: auto;
  /* keval 7-4-2023 */
  padding: 15px;
  background: var(--white);
  border-radius: 5px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 3px;
  /* border: 1px solid var(--header-color); */
}

.wifi-tip {
  font-size: 12px;
  text-align: center;
  margin: 0px;
  font-weight: 500;
}

.room-no-box {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #fff;
  border-radius: 9px !important;
  background-color: var(--darkblue);
  border: 1px solid var(--darkblue);
  font-size: 40px;
  text-align: center;
  margin: 10px auto;
  padding: 0 20px;
}

.network {
  font-size: 14px;
  color: var(--darkblue);
  font-weight: 500;
}

.wifi-box-scroll {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.wifi-device-box {
  width: 100%;
  padding: 15px;
  background: var(--white);
  border-radius: 5px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 10px 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 3px 10px 3px;
}

.wifi-device-box h1 {
  font-size: 18px;
}

.box-space-wifi {
  display: block;
  /* width: 100%; */
  /* min-width: 85px; */
}

.device-images {
  position: relative;
  display: block;
  height: 70px;
  width: 70px;
  overflow: hidden;
  border: 1px solid rgb(212, 213, 216);
  background-color: var(--white) !important;
  border-radius: 10px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
  cursor: pointer;
  margin: 0 auto;
}

.device-images img {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  padding: 10px;
  border-radius: 10px;
}

.device-name p {
  font-size: 12px;
  padding: 5px 0px;
  font-weight: 600;
  white-space: break-spaces;
  width: 70px;
  text-wrap: nowrap;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}

.support {
  font-size: 14px;
  font-weight: 600;
}

.contact-box {
  border-radius: 5px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 15px;
}

.call-images img {
  width: 12px;
}

.call-number {
  font-size: 16px;
  padding: 0 0 0 8px;
}

.wifi-modal-step {
  font-size: 12px;
  font-weight: 500;
}

.wifi-modal-step ol,
.wifi-modal-step ul {
  padding-left: 15px;
}

.model-header {
  background-color: var(--darkblue);
  color: var(--white);
  height: 43px;
  text-align: center;
  padding: 10px;
  position: relative;
  overflow: hidden !important;
}

.rs-btn-close .rs-icon {
  background-color: var(--white);
  vertical-align: middle !important;
}

.rs-modal-header {
  padding: 0;
}

.rs-modal-header .rs-modal-header-close {
  position: absolute !important;
  color: var(--darkblue);
  background-color: var(--white) !important;
  right: 15px;
  top: 10px;
  z-index: 1;
  border-radius: 10px 10px 10px 10px !important;
  overflow: hidden !important;
}

.icon-close {
  position: absolute;
}

.rs-modal-content {
  padding: 0 !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.rs-modal-header .rs-modal-header-close.rs-btn-close {
  position: absolute !important;
  color: var(--darkblue);
  background-color: var(--white) !important;
  /* box-shadow: 1px 0px 2px 1px var(--darkgray-100); */
  right: 15px;
  top: 10px;
  z-index: 1;
  border-radius: 10px 10px 10px 10px !important;
  overflow: hidden !important;
}

.rs-modal-header .rs-modal-header-close {
  position: absolute;
  right: 16px;
  top: 10px;
  font-size: 12px;
  line-height: 1.66666667;
  color: var(--dark);
  width: 20px;
  padding: 0 4px;
  z-index: 4;
}

.card-stay {
  border: 1px solid var(--white) !important;
  background-color: var(--white) !important;
  border-radius: 15px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 2px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 2px;
  cursor: pointer !important;
  padding: 10px 2px;
}

.card-stay-imgs {
  position: relative;
  display: block;
  height: 50px;
  /* keval 4-4-2023 */
  overflow: hidden;
  /* keval 5-4-2023 */
}

.card-stay-imgs img {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  height: 100%;
}

.label-name-stay {
  font-size: 12px !important;
  font-weight: 600;
  text-align: center;
}

.card-contact {
  border: 1px solid var(--white) !important;
  background-color: var(--white) !important;
  border-radius: 10px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 3px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 3px;
  padding: 15px;
}

.contact-imgs {
  position: relative;
  display: block;
  height: 70px;
}

.contact-imgs img {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  height: 100%;
}

.label-name-contact {
  font-size: 16px;
  font-weight: 500;
  color: var(--darkblue);
  text-align: center;
}

.contactus-main-label {
  font-size: 20px;
  color: var(--darkblue);
  font-weight: 600;
}

.first-popup-title {
  font-size: 18px;
  color: var(--darkblue);
  font-weight: 700;
}

.first-popup-label {
  font-size: 16px;
  font-weight: 600;
  color: var(--darkblue);
}

.first-pop-logo {
  position: relative;
  display: block;
  height: 60px;
}

.first-pop-logo img {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  right: 0px;
  left: 0px;
  margin: auto;
  width: auto;
  height: 100%;
  cursor: pointer;
}

.btn-first-popup {
  background-color: var(--btn-lightblue) !important;
  width: 100% !important;
  color: var(--white) !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 10px 20px !important;
  -webkit-box-shadow: #ccdff4 -6px -6px 13px -7px;
  box-shadow: #ccdff4 -6px -6px 13px -7px;
  border: none !important;
  margin-bottom: 12px;
}

.first-pop-up-or {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  line-height: 1.5;
  background-color: #f2f2f2;
  border: 2px solid #ebecf0;
  font-size: 15px;
}

.first-pop-up-or p {
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  font-weight: 500;
  color: var(--darkblue);
}

.rate-modal-sub-title {
  color: var(--gray);
  font-weight: 500;
  font-size: 14px;
}

.rate-box-content p {
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid var(--lightgray);
  padding: 10px;
  color: var(--darkgray);
  font-weight: 500;
}

.rate-box {
  border: 1px solid var(--lightgray);
  border-radius: 10px;
  height: auto;
}

.star-image img {
  height: 25px;
  margin: 15px;
  cursor: pointer;
}

.star-curser {
  cursor: pointer;
}

.suggestion-title {
  font-size: 14px;
  text-align: center;
}

.suggestion-text textarea {
  border-radius: 10px;
  background-color: var(--lightgray-100);
  border: none;
  height: 100px;
  width: 100%;
  padding: 10px;
  font-size: 11px;
  color: var(--darkgray-100);
  font-weight: 500;
  outline: none;
}

.request-box {
  background: var(--white);
  margin-top: 15px;
  margin-bottom: 0px;
  border-radius: 8px !important;
  border: 1px solid #d9d9d9;
  color: var(--black);
  position: relative;
}

.request-create {
  /* keval 6-4-2023 */
  padding: 10px;
  border-bottom: 1px solid #d9d9d9;
}

.request-create span {
  font-weight: 600;
  font-size: 16px;
  vertical-align: middle;
}

.request-create span p {
  font-weight: 500;
  font-size: 14px;
  color: var(--gray);
  display: inline-block;
}

.request-create span img {
  height: 20px;
  display: inline-block;
}

.ticket-no {
  background: #f0f8ff;
  border: 1px solid #6bb5ef;
  border-radius: 5px !important;
  font-size: 12px;
  height: 30px;
  color: #0180e5;
  padding: 5px;
  text-align: center;
  font-weight: 500;
}

.rate-service {
  font-size: 11px;
  color: #bcbcbc;
}

.hr {
  border-bottom: 1px solid #d9d9d9;
}

.request-item {
  font-size: 16px;
  font-weight: 600;
  color: var(--rs-btn-default-text);
  border-bottom: 1px solid #d9d9d9;
  /* keval 6-4-2023 */
  padding: 10px 15px;
}

.with-req {
  display: -ms-grid;
  display: grid;
  margin: 10px;
  -ms-grid-columns: 1fr 15px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.withdraw {
  border: 1px solid var(--gray);
  border-radius: 5px !important;
  font-size: 12px;
  height: 30px;
  color: var(--gray);
  padding: 5px;
  text-align: center;
  font-weight: 500;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  background-color: var(--white);
}

button {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

button.withdraw:hover,
button.withdraw:active,
div.withdraw:hover,
div.withdraw:active {
  background: #777777;
  color: #fff;
}

.track {
  border: 1px solid var(--btn-lightblue);
  border-radius: 5px !important;
  font-size: 12px;
  height: 30px;
  color: var(--btn-lightblue);
  padding: 5px;
  text-align: center;
  font-weight: 500;
  width: 100%;
  display: inline-block;
  margin: 0px;
  cursor: pointer;
  background-color: white;
}

button.track:hover,
button.track:active,
div.track:hover,
div.track:active {
  background: var(--btn-lightblue);
  color: #fff;
}

.reopen {
  border: 1px solid var(--green);
  border-radius: 5px !important;
  font-size: 12px;
  height: 30px;
  color: var(--green);
  /* keval 6-4-2023 */
  padding: 5px;
  text-align: center;
  font-weight: 500;
  width: 100%;
  display: inline-block;
  background-color: white;
}

button.reopen:hover,
button.reopen:active {
  background: var(--green);
  color: white;
}

.star-image-request img {
  height: 15px;
  margin: 5px;
  cursor: pointer;
}

/*  */
.edit-icon-request {
  position: fixed;
  bottom: 58px;
  right: 8px;
  cursor: pointer;
  z-index: 2;
  width: 100%;
}

.right-bottom-icons {
  position: fixed;
  bottom: 80px;
  right: 8px;
  cursor: pointer;
  z-index: 0;
}

.right-bottom-icons .filter-icons {
  position: relative;
  background-color: var(--btn-lightblue);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
/*  */

.footer {
  height: 60px;
  background-image: -o-linear-gradient(right, #0075ef, #0f0095);
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#0075ef),
    to(#0f0095)
  );
  background-image: linear-gradient(to left, #0075ef, #0f0095);
  border-radius: 12px 12px 0 0;
  overflow: hidden;
  -webkit-box-shadow: 0 -5px 25px -5px #51708e;
  box-shadow: 0 -5px 25px -5px #51708e;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.footer a {
  padding: 5px 0 0 0;
  text-align: center;
  display: block;
  border-top: 3px solid transparent;
  text-decoration: none;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.footer-icon img {
  height: 22px;
  margin: auto;
  display: block;
}

.footer-icon span {
  color: var(--white);
  font-size: 11px;
}

.footer-icon .col {
  padding: 0 4px;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.header {
  /* position: fixed; */
  position: sticky;
  top: 0;
  width: 100%;
  background-color: #dfeeff;
  padding: 5px 0px;
  z-index: 2;
}

.header-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-icon img {
  width: auto;
  height: 25px;
}

.notify-badge {
  position: relative;
  top: 3px;
  display: block;
}

.notify-text {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background-color: var(--red-100);
  display: block;
  text-align: center;
  font-size: 8px;
  line-height: 18px;
  color: var(--white);
  position: absolute;
  top: -8px;
  right: -3px;
}

.product-add-box {
  width: 100%;
  background: var(--white);
  border-radius: 10px !important;
  border: 1px solid var(--lightgray-700);
  height: 45px;
  color: var(--black);
  cursor: pointer;
}

.guest-request-maintenance-lable {
  font-weight: 500;
  font-size: 14px;
  color: var(--black);
  /* keval 4-4-2023 */
}

.item-desc {
  color: var(--gray);
  font-size: 12px;
  max-width: 120px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* keval 6-4-2023 */
.project-table {
  width: 100%;
  background: var(--white);
  border-radius: 5px !important;
  border: 1px solid var(--lightgray-700);
  /* padding: 10px; */
  max-height: 200px;
}
.project-table thead tr th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: white;
}

.project-table tr,
th,
td {
  border: none !important;
}

/* keval 4-4-2023 */
.project-table tr th {
  /* keval 6-4-2023 */
  font-weight: 500;
  color: var(--gray);
  font-size: 14px;
  padding-bottom: 0;
  padding: 5px;
}

/* keval 4-4-2023 */
.project-table tr td {
  /* keval 6-4-2023 */
  font-size: 12px;
  padding: 10px;
}

.req-item-list {
  color: var(--black);
  font-size: 14px;
  font-weight: 600;
  border-bottom: none;
  padding: 0px 5px;
}

.req-grid-height {
  max-height: calc(100vh - 50px);
  overflow: auto;
}

.guest-request-add-item {
  border: 1px solid var(--white) !important;
  background-color: var(--white) !important;
  border-radius: 10px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 20px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 20px 0px;
  width: 100%;
  height: 45px;
  font-weight: 600;
  color: var(--darkblue);
}

.add-box {
  border: 1px solid var(--white) !important;
  background-color: var(--white) !important;
  border-radius: 5px !important;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

  height: 26px;
  font-weight: 600;
  color: var(--darkblue);
  position: absolute;
  left: 15px;
}

.remove-box {
  border: 1px solid var(--white) !important;
  background-color: var(--white) !important;
  border-radius: 5px !important;
  -webkit-box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  height: 26px;
  font-weight: 600;
  color: var(--darkblue);
  position: absolute;
  right: 15px;
}

.mainten-req-sentence:first-child {
  margin-left: 0px;
}

.mainten-req-sentence {
  font-size: 16px;
  font-weight: 600;
  color: var(--darkgray-100);
  margin-left: 0px;
}

.mainten-req-sentence-desc {
  font-size: 14px;
  font-weight: 400;
  color: var(--gray);
}

.mainten-img img {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden !important;
  -webkit-box-shadow: rgba(171, 171, 177, 0.2) 0px 7px 10px 0px;
  box-shadow: rgba(171, 171, 177, 0.2) 0px 7px 10px 0px;
  cursor: pointer;
}

ul.mainten-list {
  list-style-position: outside;
  font-size: 10px;
  color: var(--lightgray-200);
}

.reopen-mainten {
  border: 1px solid #0180e5;
  border-radius: 5px !important;
  font-size: 12px;
  height: 30px;
  color: #0180e5;
  padding: 5px;
  text-align: center;
  font-weight: 500;
  margin: 8px;
}

.resolve-text {
  color: var(--green);
}

.capture-image-mainten img {
  /* keval 6-4-2023 */
  display: block;
  width: 40px;
  margin: 0;
  height: 40px;
}

input.capture-input[type="file"] {
  position: absolute;
  opacity: 0;
  padding: 0;
  width: 50px;
  height: 50px;
  min-height: 50px;
  border: none;
  cursor: pointer !important;
  display: block;
}

input.capture-input::-webkit-file-upload-button {
  cursor: pointer !important;
}

.rs-carousel {
  height: auto;
  background-color: var(--lightgray-100);
}

.rs-carousel-slider-item {
  background-color: var(--white);
  float: left;
  height: 100%;
  width: 100%;
}

.rs-carousel-label-wrapper {
  margin: 3px;
  background-color: var(--lightgray);
  border-radius: 5px 5px 5px 5px;
  width: 10px;
  height: 10px;
}

.single-image,
.multiple-image-slider {
  display: block;
  border: 1px solid #d5d5d5;
  border-radius: 6px;
  overflow: hidden;
  padding: 0px;
  height: 300px;
  background-color: var(--white);
}

.single-image img,
.multiple-image-slider img {
  display: block;
  margin: 0 auto;
  -o-object-fit: contain;
  object-fit: contain;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  background-color: var(--white);
}

.single-image video,
.multiple-image-slider video {
  display: block;
  margin: 0 auto;
  -o-object-fit: contain;
  object-fit: contain;
  overflow: hidden !important;
  width: 100%;
  height: 100%;
  background-color: var(--white);
}

.content {
  border-radius: 5px;
  background-color: var(--white);
  -webkit-box-shadow: rgba(72, 72, 119, 0.2) 0px 7px 7px 0px;
  box-shadow: rgba(72, 72, 119, 0.2) 0px 7px 7px 0px;
  padding: 15px 15px;
  margin: 15px 0;
}

.nearbyContentTitle {
  font-size: 16px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 5px;
}

.nearbyContentText {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}

.line-1 {
  height: 2px;
  background: rgb(233, 230, 230);
  width: 100%;
}

.arrow-img {
  height: 14px;
}

.content-boder-left {
  border-left: 5px solid #007bff;
}

.direction-img {
  width: 20px;
  height: 20px;
  margin: 0 auto;
}

.direction {
  font-size: 12px;
  font-weight: 500;
  color: #32a3ff;
}

.amenities-img {
  height: 65px;
  border-radius: 7px;
  width: 75px;
  margin: 0 5px;
  overflow: hidden !important;
  cursor: pointer;
}

.amenities-img:last-child {
  margin-right: 0;
}

.amenities-img:first-child {
  margin-left: 0;
}

.info-amenities img {
  height: 15px;
  cursor: pointer;
}

.text-format {
  display: -ms-grid;
  display: grid;
  font-size: 14px;
  font-weight: 500;
}

.img-scroll {
  display: inline;
  overflow: auto;
  white-space: nowrap;
}

.info-amenities {
  display: inline-block;
  height: 18px;
  cursor: pointer;
}

.info-amenities span {
  font-size: 18px;
}

.map-img {
  /* max-width: calc(100vw - 55px); */
  max-height: 300px;
  width: inherit;
  object-fit: scale-down;
  border: 1px solid var(--input-border);
  padding: 5px;
  border-radius: 4px;
}

.rs-modal-backdrop {
  z-index: 1050;
}

.text-bold {
  font-weight: bold;
}

.tvchannel-table {
  position: relative;
  height: 100%;
}

.tvchannel-header {
  background-color: var(--darkblue);
  color: var(--white);
  padding: 10px 0px;
  position: relative;
  width: 100%;
  z-index: 1;
}

.maincontent {
  background-color: var(--lightgray-400);
  border-bottom: 1px solid var(--lightgray);
  padding: 5px 0px;
}

.maincontent:last-child {
  border-bottom: 0px;
}

.channelNoboder {
  border: 1px solid var(--input-border-hover);
  border-radius: 5px;
  width: 50px;
  text-align: center;
  padding: 2px;
  margin-left: auto;
}

.tv-line {
  height: 1px;
  background: rgb(233, 230, 230);
  width: 100%;
}

.tvchannel-body {
  height: 100%;
  overflow: auto;
}

.body-content.tvchannelBody .placeHolderHeight,
.body-content.amenitiesBody .placeHolderHeight {
  height: 100vh !important;
  max-height: calc(100vh - 140px);
}

.tv-grid-height {
  background-color: var(--lightgray-400);
  padding: 0px 15px;
  height: 100%;
  max-height: calc(100% - 265px);
  overflow: auto;
  position: fixed;
  top: 210px;
  bottom: 65px;
  width: 100%;
  padding-bottom: 15px;
}

.imgsize {
  height: 50px;
  width: 50px;
  margin: 0 auto;
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: contain;
  object-fit: contain;
}

.icon-card {
  border: 1px solid var(--lightgray-100);
  border-radius: 10px !important;
  -webkit-box-shadow: var(--lightgray-200) 0px 1px 3px 1px;
  box-shadow: var(--lightgray-200) 0px 1px 3px 1px;
}

.tvchannne-box-scroll {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  white-space: nowrap;
}

.tvch-card-body {
  width: 80px;
  height: 80px;
  cursor: pointer;
  margin: 0 auto;
  position: relative;
}

.icon-card.active,
.icon-card:hover {
  background: var(--lightblue-100);
  border-color: var(--lightblue-100);
}

.icon-card.active img,
.icon-card:hover img {
  -webkit-filter: brightness(3);
  filter: brightness(3);
}

.icon-card.active .channel-name .icon-card:hover .channel-name {
  color: white;
}

.channel-name p {
  font-size: 10px;
  font-weight: 500;
  line-height: 14px;
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  color: var(--darkgray-100);
}

.btn-yes-no {
  width: 100% !important;
  color: var(--white);
  border-radius: 5px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 10px 5px !important;
  border: 1px solid #cfcfcf;
  padding-left: 10px;
  background-color: var(--blue);
  cursor: pointer;
}

.btn-hover :hover {
  background-color: var(--btn-lightblue);
  border-radius: 5px !important;
  border-color: var(--btn-lightblue);
  /* keval 3-4-2023 */
  color: white !important;
  /* keval 3-4-2023 */
}

.checkout-img-box {
  /* keval 5-4-2023 */
  border-radius: 10px !important;
  /* keval 5-4-2023 */
}

/* keval 5-4-2023 */

.text-left {
  text-align: left !important;
}

.checkout-img {
  margin: 0 auto;
  -o-object-fit: contain;
  object-fit: contain;
}

.chechout-box-space {
  text-align: center;
}

.text-size {
  font-size: 10px;
  font-weight: 600;
}

.housekeeping-box {
  padding: 15px 0px 0px;
}

.housekeeping-img-box {
  border-radius: 10px !important;
  overflow: hidden;
}

.housekeeping-img {
  height: 70px;
  width: 90px;
  padding: 10px;
  /* keval 4-4-2023 */
}

.housekeeping-later-line {
  width: 100%;
}

.form-control-sm {
  min-height: 39px !important;
  font-size: 14px !important;
  width: 100%;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: var(--lightgray-100);
  outline: none;
}

.form-control-textarea {
  min-height: 100px !important;
  font-size: 14px !important;
  width: 100%;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 0.5rem;
  background-color: var(--lightgray-100);
  outline: none;
  margin-bottom: 0px;
}

.housekeeping-rate-modal-sub-title {
  color: var(--lightblue);
  font-weight: 600;
  font-size: 12px;
}

.custom-control-input ~ .custom-control-label {
  /* keval 4-4-2023 */
  border-radius: 15px !important;
  border: 1px solid rgb(134 135 139);
  cursor: pointer;
}

.custom-control-input:checked ~ .custom-control-label {
  background: var(--lightblue-100);
  color: #fff;
  border-radius: 15px !important;
  border-color: var(--lightblue-100);
  /* keval 4-4-2023 */
}

.radio-label {
  border: none !important;
}

.custom-control-input:checked ~ .custom-control-label .housekeeping-img {
  -webkit-filter: brightness(2.5);
  filter: brightness(2.5);
}

.custom-control-input:checked ~ .custom-control-label .checkout-img {
  /* keval 5-4-2023 */
  -webkit-filter: brightness(3.5);
  filter: brightness(3.5);
}

.custom-control-label::before,
.custom-control-label::after {
  display: none;
}

.form-control-checkout {
  min-height: 39px !important;
  font-size: 14px !important;
  width: 100%;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: var(--lightgray-100);
  outline: none;
}

.task-tag-custom-control {
  background: var(--white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  gap: 10px;
  width: 100%;
  overflow-y: scroll;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.task-tag-custom-control .custom-control-tag {
  display: block;
}

.task-tag-custom-control .custom-control-tag .custom-label {
  padding: 9px 16px;
  font-size: 12px;
  line-height: 1;
  border-radius: 1.5rem;
  background: var(--warning);
  display: block;
  font-weight: 500;
  cursor: pointer;
  margin: 0 1px;
  text-align: center;
  font-size: 15px;
}

.task-tag-custom-control .custom-control-tag .custom-label-rt {
  padding: 5px 20px;
  display: block;
  font-weight: 500;
  cursor: pointer;
  margin: 0px;
  text-align: center;
  font-size: 12px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.content-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.extend-popup-body {
  font-size: 15px;
  color: var(--darkblue);
  font-weight: 700;
}

.body-fix-height {
  height: calc(100vh - 176px);
  overflow: auto;
}

.fix-height-scroll {
  max-height: calc(100vh - 130px);
  overflow: auto;
}

.bg-blue {
  background: #ecf7ff !important;
  margin: 40px 0;
  padding: 40px 0;
}

.bg-dark {
  background-color: var(--dark) !important;
}

.text-dark {
  color: var(--dark) !important;
}

.text-green {
  color: var(--green) !important;
}

.background-img {
  /* change srushti 7-3-23 */
  background-image: url(http://192.168.2.2:9999/static/media/blue.7251512affbdde4fa659.png);
  height: 100vh;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-color: #f5f9ff;
  background-color: #fff;
}

.background-header-home {
  background-image: -o-linear-gradient(right, #1973d1, #010280);
  background-image: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#1973d1),
    to(#010280)
  );
  background-image: linear-gradient(to left, #1973d1, #010280);
  padding: 15px 15px 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.home-header-left {
  margin: auto 0;
}

.tap-to-locate-block {
  background: var(--darkblue-100);
  -webkit-box-shadow: 1px 1px 10px 2px var(--lightblue-100);
  box-shadow: 1px 1px 10px 2px var(--lightblue-100);
  border-radius: 5px;
  padding: 5px;
  margin: auto 0;
}

.tap-to-locate-block img {
  height: 30px;
  display: inline-block;
  cursor: pointer;
}

.header-roomno {
  display: inline-block;
  margin-bottom: 0;
  font-size: 30px;
  line-height: 1;
  font-weight: 700;
  color: var(--white);
  vertical-align: middle;
  /* padding: 0 10px; */
  cursor: pointer;
}

.tap-to-locate-label {
  color: var(--white) !important;
  background: #3465ca;
  padding: 3px 5px;
  font-size: 9px;
  border-radius: 4px;
  text-transform: capitalize;
  line-height: 8px;
  display: block;
  text-align: center;
  font-weight: 300;
}

.tap-to-locate-block a:hover {
  text-decoration: none;
  cursor: pointer;
}

.header-menu {
  width: 93%;
  padding: 5px 0px;
  background: var(--white);
  margin: -45px auto 0px;
  border-radius: 10px !important;
  -webkit-box-shadow: #e2eff9 -1px 10px 10px -3px;
  box-shadow: #e2eff9 -1px 10px 10px -3px;
  border: 3px solid rgb(42, 91, 197);
  cursor: pointer;
}

.header-menu a {
  text-decoration: none;
  color: var(--black) !important;
}

.home-menu-border {
  border-right: 1px solid rgb(235, 232, 232);
  padding: 0px;
  text-align: center;
}

.home-menu-border:last-child {
  border-right: none;
}

.home-menu-imgs {
  position: relative;
  display: block;
  height: 40px;
  overflow: hidden;
  margin-bottom: 2px;
}

.home-menu-imgs img {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  height: 100%;
}

.home-menu-label-name {
  font-size: 12px;
  font-weight: 600;
  display: block;
  text-align: center;
}

.home-body-menu {
  border: 1px solid var(--white) !important;
  background-color: var(--white) !important;
  border-radius: 10px !important;
  height: auto;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 2px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 2px;
  cursor: pointer;
  padding: 15px;
}

.home-body-imgs {
  position: relative;
  display: block;
  height: 80px;
  overflow: hidden;
}

.home-body-imgs img {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: auto;
  height: 100%;
}

.vehicle-model-title {
  font-size: 16px;
  color: var(--darkblue);
  font-weight: 700;
}

.vehicle-model-camera-logo {
  position: relative;
  display: block;
  height: 60px;
}

.vehicle-model-camera-logo img {
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  height: 100%;
  cursor: pointer;
}

.vehicle-model-btn {
  background-color: var(--btn-lightblue) !important;
  width: 100% !important;
  color: var(--white) !important;
  border-radius: 5px !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  padding: 10px 20px !important;
  -webkit-box-shadow: #ccdff4 -6px -6px 13px -7px;
  box-shadow: #ccdff4 -6px -6px 13px -7px;
  border: none !important;
  margin-bottom: 15px;
  display: block;
  line-height: 22px;
  border: 1px solid !important;
}

.vehicle-model-or {
  width: 30px;
  height: 30px;
  margin: auto;
  border-radius: 50%;
  line-height: 1.5;
  background-color: #f2f2f2;
  border: 2px solid #ebecf0;
  font-size: 15px;
}

.vehicle-model-or p {
  margin: 0;
  padding: 4px;
  font-size: 13px;
  text-align: center;
  font-weight: 500;
  color: var(--darkblue);
}

.vehicle-model-label {
  font-size: 13px;
  font-weight: 500;
  color: var(--gray);
}

.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
  top: 10px !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600;
}

.font-weight-700 {
  font-weight: 700;
}

.text-10 {
  font-size: 10px;
}

.text-12 {
  font-size: 12px;
}

.text-14 {
  font-size: 14px !important;
}

.text-16 {
  font-size: 16px !important;
}

.text-18 {
  font-size: 18px !important;
}

.text-19 {
  font-size: 19px !important;
}

.text-20 {
  font-size: 20px !important;
}

.text-22 {
  font-size: 22px !important;
}

.text-24 {
  font-size: 24px !important;
}

.text-26 {
  font-size: 26px !important;
}

.text-28 {
  font-size: 28px !important;
}

.text-30 {
  font-size: 30px !important;
}

.text-justify {
  text-align: justify !important;
}

.card-content {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.wrapper {
  font-size: 14px;
}

.StepProgress {
  position: relative;
  padding-left: 30px;
  list-style: none;
}

.StepProgress::before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  left: 15px;
  width: 10px;
  height: 100%;
  border-left: 2px dashed var(--lightgray-300);
}

.StepProgress-item {
  position: relative;
  counter-increment: list;
}

.StepProgress-item:not(:last-child) {
  padding-bottom: 25px;
}

.StepProgress-item:last-child:before {
  border: 0px !important;
}

.StepProgress-item::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: -30px;
  height: 100%;
  width: 10px;
}

.StepProgress-item::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  left: -35px;
  width: 11px;
  height: 11px;
  border: 2px solid #ccc;
  border-radius: 50%;
  background-color: #fff;
}

.StepProgress-item.current.is-done::before {
  border-left: 2px solid var(--green-100);
}

.StepProgress-item.current.is-done::after {
  /* content: "✔"; */
  font-size: 8px;
  color: #fff;
  text-align: center;
  border: 2px solid var(--green-100);
  background-color: var(--green-100);
}

.StepProgress-item.current.d-block.is-done:first-child::before {
  border: 0;
}

.StepProgress-item.current::after {
  width: 8px;
  height: 8px;
  left: -33px;
  font-size: 10px;
  text-align: center;
  border: 2px solid var(--lightgray-300);
  background-color: var(--lightgray-300);
}

.StepProgress .progress-title {
  display: inline-block;
  color: var(--darkgray-100);
  font-size: 14px;
  font-weight: 600;
  line-height: 10px;
}

.progress-detail {
  color: var(--black);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin: 2px 0px;
}

.request-item-progress {
  font-size: 18px;
  font-weight: 600;
  color: var(--dark);
  width: 100%;
}

.request-item-progress p {
  font-size: 14px;
  font-weight: 500;
  color: var(--gray);
  margin-top: 5px;
}

.progress-date {
  font-size: 12px !important;
  color: var(--gray);
  font-weight: 500;
  line-height: 10px;
}

.progress-date-main {
  font-size: 14px;
  color: var(--gray);
  font-weight: 500;
  display: inline-block;
}

.progress-desc p {
  font-size: 12px;
  color: var(--gray);
  font-weight: 500;
  text-align: justify;
}

.close-icon {
  cursor: pointer;
}

.notification-date-text span {
  color: var(--darkblue-200);
  font-weight: 600;
  font-size: 12px;
}

.notification-box {
  width: 100%;
  background: var(--white);
  border-radius: 5px !important;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 4px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 10px 4px;
  padding: 10px 10px 15px 10px;
  cursor: pointer;
}

.notification-img img {
  height: 50px;
  border-radius: 30px;
}

.notification-text span {
  font-size: 14px;
  font-weight: 600;
  max-width: 140px;
  min-width: 100%;
  width: 100%;
  padding: 0;
  position: relative;
}
.announcement-subdesc {
  font-size: 14px !important;
  font-weight: 400 !important;
}

.notification-text p {
  font-size: 12px;
  font-weight: 600;
  margin-left: 4px;
  /* keval 3-4-2023 */
}

.notification p {
  font-size: 10px;
  font-weight: 600;
  color: var(--darkblue-300);
}

.notification img {
  height: 10px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  margin: 2px 2px 0 0;
}

.notification-box-border {
  border-left: 1px solid rgb(235, 232, 232);
  padding-left: 5px;
  width: 22%;
}

.notification-box-border span {
  font-size: 10px;
  font-weight: 500;
  color: var(--primary);
}

.rs-input:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.btn-success {
  background-color: var(--success) !important;
}

.margin-left-30 {
  margin-left: 30px !important;
}

.margin-bottom-25 {
  margin-bottom: 25px !important;
}

.border-radius-5 {
  border-radius: 5px !important;
}

.rs-modal-title span.formnamelbl-checkin {
  white-space: break-spaces;
}

/* tree */
.tree-tag-picker {
  background-color: var(--calendar-light);
  border-radius: 5px;
  min-height: 400px;
}

.tree-tag-control .tree-tag-label {
  font-size: 17px;
  font-weight: 700;
}

.tree-tag-control .tree-label-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.tree-tag-text {
  cursor: pointer;
  background-color: var(--white);
  text-align: center;
  height: 42px;
  width: auto;
  -webkit-box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.5);
  box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.5);
  font-weight: 700;
  font-size: 17px;
  border-radius: 8px;
}

.tree-tag-control .tree-label-box .tree-label-text {
  cursor: pointer;
  background-color: var(--white);
  text-align: center;
  height: 35px;
  padding: 5px 15px !important;
  -webkit-box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.2);
  box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.2);
  font-weight: 600;
  font-size: 14px;
  border-radius: 1.5rem;
  margin-right: 10px;
}

.redirection-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  line-height: 1;
  vertical-align: middle;
}

.redirection-icon i {
  font-size: 20px;
}

.back-header {
  margin: 8px 0;
  line-height: 20px;
  color: var(--darkblue);
  font-weight: 600;
  text-decoration: none !important;
}

a,
a:hover {
  /* keval 7-4-2023 */
  text-decoration: none !important;
}

.body-content {
  bottom: 65px;
  height: 100%;
  max-height: calc(100% - 105px);
  overflow: auto;
  /* padding-bottom: 15px; */
  position: fixed;
  top: 45px;
  width: 100%;
}

.container {
  margin-right: auto;
  margin-left: auto;
  padding: 0 !important;
  background-color: #f5f7f9;
  overflow: hidden;
  position: relative;
}

.closeicon-guest-req,
.closeicon-rep-prob {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 15px !important;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
  cursor: pointer;
  /* keval 7-4-2023 */
}

.images-block {
  display: -ms-grid;
  display: grid;
  gap: 15px;
  -ms-grid-columns: auto 15px auto 15px auto 15px auto 15px auto;
  grid-template-columns: auto auto auto auto auto;
  margin-bottom: 0px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.img-block {
  /* keval 5-4-2023 */
  display: block;
  border-radius: 6px;
  height: 60px;
  width: 60px;
  margin: 0px;
  position: relative;
  border: 1px solid var(--input-border-hover);
}

.img-block .img-wrap {
  /* keval 6-4-2023 */
  margin: 2px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 4px;
}

.img-block img {
  /* keval 5-4-2023 */
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.img-block i.cancel-btn {
  /* keval 5-4-2023 */
  position: absolute;
  right: -8px;
  top: -8px;
  left: auto;
  bottom: auto;
  background-color: #fff;
  border-radius: 50%;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
}

.task-btn-custom-control {
  border-radius: 10px !important;
  overflow: hidden;
}

/* priority button radio 5-4-2023 */
.task-btn-custom-control .custom-control {
  display: inline-block;
  margin: 0 !important;
  min-height: auto;
  background-color: rgb(249, 249, 249);
  padding: 5px 5px;
  width: 33%;
}

.task-btn-custom-control .custom-control .custom-control-label {
  padding: 7px 9px;
  font-size: 14px;
  line-height: 1;
  border-radius: 4px !important;
  background: var(--transparent);
  display: block;
  font-weight: 500;
  cursor: pointer;
  margin: 0 1px;
  text-align: center;
}

.task-btn-custom-control .custom-control input:checked ~ .custom-control-label {
  background: var(--orange);
}

.request-box .request-card-body {
  padding: 10px 15px
}

.request-card-body {
  display: flow-root;
  /* padding-bottom: 15px;
  border-bottom: 1px solid #d9d9d9; */
}

.request-card-body-img {
  width: 100px;
  height: 100px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  -webkit-box-shadow: rgba(171, 171, 177, 0.2) 0px 7px 10px 5px;
  box-shadow: rgba(171, 171, 177, 0.2) 0px 7px 10px 5px;
  background: #fff;
  border-radius: 4px;
  vertical-align: middle;
  margin-right: 10px;
  float: left;
}

.request-card-body-img figure {
  margin: 5px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 4px;
}

.request-card-body-img figure img {
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  cursor: pointer;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  /* 6-4-2023 */
}

.mainten-desc {
  padding: 0px;
  width: 100%;
}

.task-btn-custom-control .custom-control {
  display: inline-block;
  margin: 0 !important;
  min-height: auto;
  background-color: rgb(249, 249, 249);
  padding: 5px 5px;
}

.area-boder {
  background-color: #000;
}

.btn-sm {
  padding: 12px !important;
  font-size: 14px !important;
  border-radius: 6px !important;
  margin: 0;
  width: 100% !important;
}

.rs-panel-header {
  padding: 10px 15px;
  font-size: 16px;
}

.rs-panel-collapsible > .rs-panel-header > .rs-icon {
  position: absolute;
  top: 12px !important;
  right: 12px;
}

.rs-panel-body {
  /* keval 6-4-2023 */
  padding: 0 0 12px 0 !important;
}

img.nearby-img {
  width: 60px;
  height: 60px;
  border-radius: 4px;
  margin: 0 auto;
  -o-object-fit: cover;
  object-fit: cover;
}

.container-scheckout {
  height: 300px;
  width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
}

/* srushti report problem close button */
.material-icons-round {
  font-family: "Material Icons Round";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  cursor: pointer;
  -webkit-font-feature-settings: "liga";
  -webkit-font-smoothing: antialiased;
}

.remove-roomarea {
  position: absolute;
  width: 22px;
  height: 22px;
  top: -5px;
  right: -5px;
  background-color: var(--white);
  cursor: pointer;
  color: var(----gray-600);
  font-size: 22px;
  border-radius: 50%;
}

.tree-label-box .tree-label-text {
  margin-right: 12px;
}

.tree-label-text {
  cursor: pointer;
  background-color: var(--white);
  text-align: center;
  height: 40px;
  -webkit-box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.5);
  box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.5);
  font-weight: 600;
  font-size: 14px;
  border-radius: 1.5rem;
  margin-right: 0px;
  margin-bottom: 10px;
}

.position-relative {
  position: relative !important;
}

.material-icons-outlined {
  cursor: pointer !important;
}

.area-list {
  font-size: 15px;
  font-weight: 600;
  color: #565656;
  padding: 10px;
}

.timining-table table {
  width: 100%;
  background: var(--white);
  border: 1px solid var(--lightgray-700);
  border-radius: 5px !important;
  font-size: 13px !important;
}

.timining-table td .th {
  text-align: left;
  padding: 8px !important;
}

.tagactive {
  background-color: var(--lightgray-300);
  color: white;
}

.btn-secondary-light {
  background-color: var(--lightgray-200) !important;
  border-color: var(--lightgray-200) !important;
}

.btn-secondary-light:hover {
  background-color: var(--lightgray-200) !important;
  border-color: var(--lightgray-200) !important;
}

.btn-primary-light {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
}

.btn-primary-light:hover {
  background-color: var(--blue) !important;
  border-color: var(--blue) !important;
}

.project-table .table thead th {
  vertical-align: baseline;
  border-bottom: 1px solid var(--lightgray) !important;
}

.project-table .table tr:nth-child(even) {
  background-color: var(--lightdarkgray);
}

.project-table .table td:last-child {
  vertical-align: middle;
}

.rs-placeholder-graph {
  background-color: var(--rs-placeholder-active);
  border-radius: 8px;
  margin-bottom: 10px;
}

.no-data-found img {
  width: 250px;
  height: 250px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.noData-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.no-data-found {
  position: relative;
  height: calc(100vh - 150px);
}

.gridBox {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto 20px auto 20px auto;
  grid-template-columns: auto auto auto;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
  position: relative;
  z-index: 0;
}

.ratingTagGrid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 15px;
  border-top: 1px solid var(--lightgray);
}

.validate-input.error .form-control.rs-picker .rs-btn {
  border-color: transparent;
}

.placeHolderHeight {
  height: 100% !important;
}

.submit-btn-sec a {
  color: var(--white) !important;
  line-height: 22px;
  display: block;
  text-align: center;
}

.notifyText {
  line-height: 42px;
}

.submit-btn-sec.set-clearbtn {
  margin-bottom: 15px;
}

.box-space-wifi .device-images img {
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.nearbyContent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
}

.directionContent {
  display: -ms-grid;
  display: grid;
  gap: 5px;
}

.content.content-boder-left .nearbyContent {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.amenitiesBox .card-stay {
  padding: 5px;
}

.amenitiesBox .card-stay-imgs {
  height: 100px;
}

.amenitiesBox .card-stay-imgs img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

.notificationContent {
  gap: 10px;
  width: 78%;
  position: relative;
}
.notificationContent span.blink-red-dot {
  left: -12px;
  top: -12px !important;
}
.notification-text {
  padding: 5px;
  width: 100%;
}

.allImages img {
  width: 100%;
  height: 100%;
  overflow: hidden !important;
  -o-object-fit: cover;
  object-fit: cover;
}

.nearDirection {
  display: -ms-grid;
  display: grid;
  gap: 5px;
}

.icon-card.active .channel-name p {
  color: var(--white);
}

.icon-card.active {
  border-color: var(--lightblue-100);
}

.tvchannne-box-scroll {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  white-space: nowrap;
  gap: 15px;
  padding: 15px;
}

.tableHeader {
  font-size: 14px;
  font-weight: 500;
}

.tableContent {
  font-size: 14px;
  font-weight: 500;
}

.tableImg {
  width: 40px;
  height: 40px;
  position: relative;
  display: block;
}

.tableImg img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  border: 1px solid var(--input-border);
  padding: 2px;
  background-color: var(--white);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -o-object-fit: cover;
  object-fit: cover;
}

.header-top-icon {
  height: 35px;
}

.header-top-icon img {
  width: auto;
  height: 100%;
}

.tvchannelInner {
  gap: 15px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 15px 100px;
  grid-template-columns: 1fr 100px;
  padding: 5px 15px;
}

.tvchannelContent {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 40px 15px 1fr;
  grid-template-columns: 40px 1fr;
  gap: 15px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-modules {
  /*charmi(09-08-2023 12:24)*/
  /* padding-left: 0; */
  position: relative;
  padding: 0 5px;
  /*charmi(09-08-2023 12:24)*/
}

.wifi-room-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}

.material-icons-round {
  color: var(--dark);
}

.submit-btn-sec {
  margin-bottom: 0;
  display: block;
}

.text-format.hours-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
}

.project-table.time-table-modal {
  padding: 0;
}

.project-table.time-table-modal tr td,
.project-table.time-table-modal .table thead th {
  padding: 5px 15px;
}

.closeicon img {
  border: 1px solid var(--lightgray);
  border-radius: 4px;
  background-color: var(--white);
  margin-right: 5px;
}

.wakeup-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 100px 10px 1fr 10px 100px;
  grid-template-columns: 100px 1fr 100px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
}

.wakeup-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  color: var(--darkblue);
}

.wakeup-sub-text {
  font-size: 10px;
  font-weight: 600;
  line-height: 14px;
  color: var(--lightgray-300);
  margin: 0;
}

.wakeup-label {
  font-size: 14px;
  line-height: 16px;
  font-weight: 600;
  color: var(--darkblue);
}

.wakeup-content .rs-toggle {
  margin-bottom: 0 !important;
}

.notify-content {
  background: -o-linear-gradient(right, #fff, rgb(0 113 236 / 5%));
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#fff),
    to(rgb(0 113 236 / 5%))
  );
  background: linear-gradient(to left, #fff, rgb(0 113 236 / 5%));
  padding: 15px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.12);
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.12);
  margin: 15px 0px;
}

.wakeup-inner-label {
  text-align: center;
}

.wakeup-sub-text span {
  padding: 0px 2px;
}

.wakeup-sub-text .active {
  color: var(--darkblue-100);
  font-weight: 700;
}

.placeholder-img {
  width: 70px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
  border: 1px solid var(--lightgray-500);
  border-radius: 5px;
  opacity: 0.5;
}

.notify-graph {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.notify-graph-content {
  width: 100%;
  display: block;
}

.graph {
  background-color: var(--lightgray-500);
  height: 10px;
  margin-bottom: 13px;
}

.graph:nth-child(1) {
  width: 90%;
}

.graph:nth-child(2) {
  width: 80%;
}

.graph:nth-child(3) {
  width: 100%;
}

.notify-inner {
  padding: 15px;
  height: 90px !important;
}

.completed-content {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* width: 100%; */
}

.successfulcheckin-desc p {
  font-size: 18px;
}

.body-fix-height.checkout-content {
  height: 100vh;
}

.body-content.chat-box {
  background-color: #e8e8e8;
  /* background-color: rgb(170 213 255 / 25%); */
  padding-bottom: 0px;
  overflow: hidden;
}

/* chat-style start*/

.chat-date {
  font-size: 12px;
  font-weight: 500;
  background-color: var(--rs-blue-100);
  padding: 4px 8px;
  text-align: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 5px;
  color: var(--darkblue-200);
  margin: 15px auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.chat-name {
  font-size: 12px;
  font-weight: 500;
  display: block;
  margin: 0px;
  /*5px */
  color: var(--darkblue-200);
  /* margin-top: 10px; */
}

.chat-container .chat-text {
  font-size: 12px;
  font-weight: 500;
  color: var(--black);
  padding: 10px 20px 18px 10px;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: 5px;
  margin-bottom: 5px;
  width: 100%;
  max-width: 80px;
  position: relative;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.chat-item {
  width: 100%;
}

.chat-item.left {
  float: left;
}

.chat-item.right {
  float: right;
}

.chat-item.left .chat-text {
  /* background-color: #edf0f3; */
  background-color: #fff;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  -webkit-box-shadow: 2px 1px 3px -1px #dee2e6;
  /* box-shadow: 2px 1px 3px 0px #cdcdcd; */
  box-shadow: 2px 1px 3px -1px #dee2e6;
  margin-right: auto;
}

.chat-item.right .chat-text {
  /* background-color: #3498ff40; */
  background-color: #daecfd;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  -webkit-box-shadow: -1px 2px 2px 0px #bdd9f2;
  box-shadow: -1px 2px 2px 0px #bdd9f2;
  margin-left: auto;
}

.chat-item.right .chat-name {
  text-align: right;
}

.chat-profile {
  width: 30px;
  height: 30px;
  background-color: var(--header-color);
  text-align: center;
  font-size: 12px;
  line-height: 30px;
  border-radius: 50%;
  color: var(--black) !important;
  font-weight: 600;
  -webkit-box-shadow: 0px 1px 2px 1px var(--lightgray-100);
  box-shadow: 0px 1px 2px 1px var(--lightgray-100);
  display: block;
}

.chat-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  padding: 0px 15px;
}

.body-content.chat-box > div {
  height: 100%;
}

.chat-profile img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 50%;
}

.chat-time {
  position: absolute;
  right: 6px;
  bottom: 3px;
  font-size: 10px;
  color: var(--rs-btn-subtle-text);
  font-weight: 400;
  width: 95%;
  text-align: right;
}

.chat-bottom-msg {
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 0px var(--header-color);
  box-shadow: 0px 0px 5px 0px var(--header-color);
  padding: 15px;
  display: block;
  z-index: 9;
}

.chat-block-content {
  height: 100%;
  overflow: auto;
}

.chat-bottom-input-field {
  border: 1px solid var(--input-border);
  border-radius: 5px;
  width: 100%;
  resize: none;
  display: block;
  padding: 5px 85px 5px 30px;
  line-height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: var(--black);
}

.chat-bottom-icon i,
.chat-bottom-send i {
  color: var(--darkblue-100);
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.chat-bottom-icon,
.chat-bottom-send {
  position: absolute;
  display: block;
}

.chat-bottom-icon {
  left: 5px;
  top: 11px;
  background-color: transparent;
  padding: 0 !important;
}

.chat-bottom-send {
  right: 21px;
  bottom: 24px;
}

.chat-bottom-send i {
  background-color: var(--darkblue-100);
  border-radius: 50%;
  color: var(--white);
  font-size: 12px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
}

textarea:focus {
  outline: 0;
}

.chat-block-inner-content {
  height: 100%;
  max-height: calc(100vh - 170px);
  overflow: auto;
  padding: 0px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 55px;
}

/* add JENIL 05/08/2023 4:14 PM */
.chat-block-inner-content.chat-block-inner-content-bottom-zero {
  bottom: 10px;
  max-height: calc(100vh - 125px);
}

/* chat-style end*/

/* chat-icon start*/
.chat-bottom-icon:hover,
.chat-bottom-icon:focus {
  background-color: transparent !important;
}

.drop-down-conntents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  gap: 10px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.drop-down-conntents .title {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.drop-down-conntents .text {
  font-size: 9px;
  line-height: 10px;
  font-weight: 400;
  color: var(--dark);
}

.drop-icons span {
  font-size: 16px;
  line-height: 18px;
}

.chat-drop-down {
  display: block;
  width: 280px !important;
}

/* chat-icon end*/

.wakeup-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.wakeup-inner-content {
  width: 40px;
}

.wakeup-inner-content label {
  background-color: var(--header-color);
  padding: 5px;
  border-radius: 20px;
  font-size: 10px;
  font-weight: 500;
  color: #000;
  display: block;
  margin: 0;
  text-align: center;
  cursor: pointer;
}

.wakeup-inner-content label.active {
  background-color: var(--darkblue-100);
  color: var(--white);
}

.standard .drop-icons span,
.standard .title {
  color: var(--primary);
}

.important .drop-icons span,
.important .title {
  color: var(--orange);
}

.urgent .drop-icons span,
.urgent .title {
  color: var(--red-100);
}

.checked-icon {
  font-size: 16px !important;
  color: var(--green-100);
}

.drop-down-conntents-inners {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}

.chat-drop-down li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.chat-bottom-attachment.footer-icon input[type="file"] {
  position: absolute;
  opacity: 0;
  padding: 0 !important;
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer !important;
  left: 3px;
  top: 1px;
}

.chat-bottom-attachment {
  right: 50px;
  bottom: 15px;
  color: var(--dark);
  padding: 13px;
}

.chat-bottom-icon,
.chat-bottom-send,
.chat-bottom-attachment {
  position: absolute;
  display: block;
}

.chat-bottom-icon i,
.chat-bottom-send i,
.chat-bottom-attachment i {
  color: var(--darkblue-100);
  font-size: 20px;
  font-weight: 600;
  display: block;
}

.chat-bottom-send i,
.chat-bottom-send span {
  background-color: var(--darkblue-100);
  border-radius: 50%;
  color: var(--white);
  font-size: 12px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
}

.chat-img {
  padding: 0px !important;
  position: relative;
  width: 250px;
  height: 250px;
  display: block;
  overflow: hidden;
  max-width: unset !important;
}

.chat-item.right .chat-img,
.chat-item.left .chat-img {
  border: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}

.chat-item.right .chat-img video {
  height: auto;
}
.chat-img video {
  width: 100% !important;
  height: 100% !important;
  margin: auto;
  top: 0;
  bottom: 0;
}

.chat-img img,
.chat-img video {
  width: initial;
  max-width: none;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: inherit;
}

.gap-10 {
  gap: 10px;
}

.gap-5 {
  gap: 5px;
}

.chat-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  padding: 5px 10px 5px 5px;
  border-radius: 6px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-item.right .chat-file {
  background-color: var(--btn-primary-transparent);
}

.chat-item.left .chat-file {
  background-color: var(--lightgray-100);
}

.chat-file img {
  width: 25px;
  height: 25px;
  -o-object-fit: cover;
  object-fit: cover;
}

.chat-file-content.chat-text {
  padding: 10px 10px 18px 10px !important;
}

.chat-file-content .chat-file a,
.chat-file-grid .chat-img-block span {
  max-width: 200px;
  width: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--black) !important;
  font-size: 12px;
}

.chat-item.right .profile {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
}

.chat-text.important .important-text,
.chat-text.urgent .urgent-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  line-height: 14px;
  font-size: 12px;
}

.chat-text.urgent .urgent-text {
  color: var(--red-100);
}

.chat-text.important:before,
.chat-text.urgent:before {
  content: "";
  width: 4px;
  height: 50px;
  display: block;
  position: absolute;
  left: -4px;
  top: 10px;
  z-index: 0;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.chat-text.urgent:before {
  background-color: var(--red-100);
}

.chat-text.important .important-text i,
.chat-text.urgent .urgent-text i {
  font-size: 16px;
}

.chat-text.important:before {
  background-color: var(--orange);
}

.chat-text.important .important-text {
  color: var(--orange);
}

.left .chat-text.important:before,
.left .chat-text.urgent:before {
  right: -4px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  left: unset;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.chat-img-block i.cancel-btn,
.chat-img-close i.cancel-btn {
  position: absolute;
  font-size: 12px;
  background: var(--white);
  border-radius: 50%;
  right: 10px;
  left: auto;
  top: 0;
  bottom: auto;
  margin: auto;
  color: var(--btn-danger) !important;
  cursor: pointer;
  -webkit-box-shadow: 0px 1px 2px 1px #ddd;
  box-shadow: 0px 1px 2px 1px #ddd;
  padding: 2px;
  font-weight: 600;
}
.chat-img-close i.cancel-btn {
  right: -5px;
  left: unset;
  top: 20px;
}

.chat-img-block img {
  height: 40px;
  width: 40px;
  padding: 3px;
  border: 1px solid var(--input-border-hover);
  border-radius: 4px;
  -o-object-fit: cover;
  object-fit: cover;
}

.chat-img-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  background: var(--gray-100);
  border-radius: 6px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 50px;
  padding: 5px;
  margin: 6px 0;
  font-size: 14px;
}

.chat-tag.important,
.chat-tag.urgent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 15px;
}

.chat-tag .important-close-icon {
  margin-top: 0;
  cursor: pointer;
}

.chat-bottom-msg .chat-tag.important {
  color: var(--orange);
}

.chat-bottom-msg .chat-tag.urgent {
  color: var(--danger);
}

body {
  background-color: #fff;
  /* height: 100%; */
  overflow: hidden;
}

.important-text {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin: 0px;
}

.card-content p {
  margin: 0;
}

.important-close-icon i {
  font-size: 14px;
  line-height: 14px;
  display: block;
  cursor: pointer;
}

.chat-blank-img img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 70%;
  display: block;
  padding: 0px 15px;
}

.time-text {
  font-weight: 600;
  color: var(--darkblue);
  font-size: 28px;
  line-height: 28px;
}

.top-bar-chat {
  background-color: #ffff;
  width: 100%;
  -webkit-box-shadow: 0px 0px 5px 0px var(--lightgray-100);
  box-shadow: 0px 0px 5px 0px var(--lightgray-100);
  position: fixed;
  z-index: 1;
  display: block;
  /* padding: 15px 15px 0px; */
  padding: 15px;
}

.chat-profile-back {
  display: block;
}

.chat-profile-back span {
  display: block;
  font-size: 22px;
  position: relative;
  top: -5px;
  cursor: pointer;
  color: var(--dark);
}

.chat-profile-icon {
  font-size: 16px;
  font-weight: 600;
}

.chat-profile-block {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 10px;
  margin-top: 5px;
}

.chat-search-form input.form-control {
  border-radius: 20px !important;
  font-size: 12px;
  padding: 5px 15px;
  font-weight: 400;
}

.chat-search-form .material-icons-round {
  font-size: 14px !important;
  display: block !important;
  position: absolute;
  top: 2px;
  right: 5px;
  width: 30px !important;
  margin: 0 !important;
  height: 30px !important;
  line-height: 20px;
}

.chat-search-form input.form-control:focus {
  -webkit-box-shadow: 1px 1px 4px 1px #ddd !important;
  box-shadow: 1px 1px 4px 1px #ddd !important;
}

/* searchbar */
.chat-search-form .search-input-1 {
  width: 100%;
  max-width: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
  top: 0;
  right: 55px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
  outline: none;
  border: none;
  padding: 8px 15px !important;
  -webkit-box-shadow: 1px 1px 4px 1px #ddd;
  box-shadow: 1px 1px 4px 1px #ddd;
  min-height: 34px !important;
  font-size: 12px !important;
}

.chat-search-form .search-expanded {
  max-width: calc(100% - 165px);
  opacity: 1;
}

.search-wrapper {
  padding: 6px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: var(--dark);
  position: absolute;
  top: 5px;
  right: 15px;
  border-radius: 5px;
  z-index: 9;
}

.search-wrapper {
  color: var(--white) !important;
}

/* searchbar */
.search-input-1 {
  width: 100%;
  max-width: 0;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  position: absolute;
  top: 5px;
  right: 55px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  opacity: 0;
  outline: none;
  border: 1px solid var(--input-border);
  padding: 8px 15px !important;
  -webkit-box-shadow: 0px 1px 2px 0px #ddd;
  box-shadow: 0px 1px 2px 0px #ddd;
  z-index: 9;
}

.search-expanded {
  max-width: calc(100% - 100px);
  opacity: 1;
}
.search-input-1.form-control {
  min-height: 35px !important;
  border-radius: 8px !important;
  padding: 10px 40px 10px 15px !important;
}
.search-btn i {
  color: var(--white);
  font-size: 14px;
}

.search-btn {
  position: absolute;
  top: 10px;
  right: 60px;
  z-index: 9;
  background: var(--gray);
  color: var(--white);
  padding: 4px 8px;
}

.search-input-top {
  position: absolute;
  top: 0;
  left: 0;
}

.search-expanded + .search-btn {
  visibility: visible;
}
.search-btn {
  visibility: hidden;
}
.search-btn:hover {
  background-color: var(--orange);
  border-color: var(--orange);
}
/* chat-style start*/
.chat-top-bar-icon {
  display: block;
  padding: 5px;
  background-color: var(--dark);
  border-radius: 4px;
  width: 35px;
  /* margin: 0 auto; */
}

.chat-top-bar-icon span {
  color: var(--white);
}

.chat-top-bar-icon a {
  line-height: 25px;
  color: var(--white) !important;
}

.hide-marker:after {
  opacity: 0;
}

.dropdown-tag-content.rs-tooltip .mb-2:last-child {
  margin-bottom: 0 !important;
}

.dropdown-tag-content.rs-tooltip {
  padding: 10px;
}

.dropdown-tag-content.rs-tooltip .drop-down-conntents:last-child {
  margin-bottom: 0px;
}
.chat-search-form {
  position: absolute;
  right: 95px;
  top: 1px;
  width: 100%;
}
.report-or-border {
  font-size: 14px;
  font-weight: 600;
  color: var(--darkblue);
  text-align: center;
  position: relative;
}

.report-or-border:after {
  content: "";
  position: absolute;
  width: 45%;
  height: 1px;
  background-color: var(--lightgray-500);
  top: 10px;
  left: 0;
}

.report-or-border:before {
  content: "";
  position: absolute;
  width: 45%;
  height: 1px;
  background-color: var(--lightgray-500);
  top: 10px;
  right: 0;
}

.body-content.tvchannelBody {
  overflow: hidden;
}

.tvchannel-body {
  height: 100%;
  overflow: auto;
}

.chat-item.right .chat-text.corner-border img,
.chat-item.right .chat-text.corner-border video,
.chat-item.right .chat-text.corner-border {
  border-top-right-radius: 0px !important;
}

.chat-item.left .chat-text.corner-border img,
.chat-item.left .chat-text.corner-border video,
.chat-item.left .chat-text.corner-border {
  border-top-left-radius: 0px !important;
}

.rs-carousel-slider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.chat-item.right .w-90 {
  width: 90%;
  margin-left: auto;
}

.chat-item.left .w-90 {
  width: 90%;
  margin-right: auto;
}

.message-wrap {
  white-space: break-spaces;
  overflow-wrap: anywhere;
  font-size: 12px;
  font-weight: 500;
}

/* body.rs-modal-open.rs-modal-has-backdrop {
overflow: auto !important;
} */

/* timeline */
.timeline {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
  direction: ltr;
}

.timeline-align-left .timeline-item {
  padding-left: 30px;
}

.timeline-item {
  position: relative;
  text-align: left;
  cursor: pointer;
}

.timeline-item-dot.completed::before {
  background-color: var(--success);
}

.timeline-item-dot::before {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  background-color: var(--lightgray-300);
  border-radius: 50%;
}

.timeline-align-left .timeline-item-dot {
  left: 12px;
}

.timeline-item-dot {
  position: absolute;
  top: 0px;
}

.timeline-item:first-child .timeline-item-tail {
  top: 5px;
}

.timeline-item-tail.completed {
  border-left: 2px solid var(--success);
}

.timeline-align-left .timeline-item-tail {
  left: 15px;
}

.timeline-item-tail {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2px;
  border-left: 2px dashed var(--lightgray-300);
}

.progress-title {
  display: inline-block;
  color: var(--darkgray-100);
  font-size: 13px;
  font-weight: 600;
  line-height: 10px;
}

.timeline-item:last-child .timeline-item-tail {
  border: 0;
}

.hidden-message {
  display: none;
}

/*charmi(01-05-2023)*/
.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner:after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  -o-transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: block;
}

.active.carousel-item-end,
.carousel-item-next:not(.carousel-item-start) {
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
}

.active.carousel-item-start,
.carousel-item-prev:not(.carousel-item-end) {
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end,
.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-end,
.carousel-fade .active.carousel-item-start {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  -o-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-end,
  .carousel-fade .active.carousel-item-start {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: var(--white);
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  -o-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next,
  .carousel-control-prev {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-control-next:focus,
.carousel-control-next:hover,
.carousel-control-prev:focus,
.carousel-control-prev:hover {
  color: var(--white);
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3E%3Cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators [data-bs-target] {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: var(--white);
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  -o-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }
}

.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: var(--white);
  text-align: center;
}

.carousel-dark .carousel-control-next-icon,
.carousel-dark .carousel-control-prev-icon {
  -webkit-filter: invert(1) grayscale(100);
  filter: invert(1) grayscale(100);
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: var(--dark);
}

.carousel-dark .carousel-caption {
  color: var(--dark);
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.hidden-message {
  display: none;
}

/*charmi(01-05-2023)*/
.scheckout-img {
  width: 250px;
  /* height: 250px; */
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.only-scheckout {
  height: 250px;
}

/* carousel-start */
.carousel-control-next-icon,
.carousel-control-prev-icon {
  width: 20px;
  height: 20px;
  background-size: 60% 60%;
  background-color: var(--primary);
  border-radius: 50%;
  padding: 15px;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.carousel-indicators [data-bs-target] {
  background-color: var(--primary);
}

.audio-3x3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

/* carousel-end */

span.blink-red-dot {
  position: absolute;
  display: block;
  background: #d30808;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  -webkit-animation: mymoveroom 2s infinite;
  animation: mymoveroom 2s infinite;
  top: 15% !important;
  right: 13% !important;
  padding: 2px;
}

span.blink-red-dot.home-page-dot {
  top: 0px !important;
  right: 8px !important;
}
.card-stay span.blink-red-dot {
  top: 10px !important;
  right: 8px !important;
}
span.blink-red-dott {
  position: absolute;
  display: block;
  background: #b50707;
  width: 5px;
  height: 5px;
  border-radius: 100%;
  -webkit-animation: mymoveroom 2s infinite;
  animation: mymoveroom 2s infinite;
  top: 5px !important;
  right: 23px !important;
  padding: 2px;
  z-index: 1;
}

.serachclose i {
  font-size: 14px;
  font-weight: 600;
  color: var(--danger);
}

.serachclose {
  position: absolute;
  right: 10px;
  font-size: 12px;
  top: 15px;
}

.searchinput.form-control {
  padding-right: 30px !important;
}

@-webkit-keyframes mymoveroom {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(255 15 15 / 100%), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 0 rgb(255 15 15 / 100%), 0 0 0 0 rgb(255 1 1);
  }

  40% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(255 1 1);
  }

  80% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 64, 0),
      0 0 0 10px rgba(255, 0, 64, 0);
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 64, 0),
      0 0 0 10px rgba(255, 0, 64, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
  }
}

@keyframes mymoveroom {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(255 15 15 / 100%), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 0 rgb(255 15 15 / 100%), 0 0 0 0 rgb(255 1 1);
  }

  40% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(255 1 1);
  }

  80% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 64, 0),
      0 0 0 10px rgba(255, 0, 64, 0);
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 64, 0),
      0 0 0 10px rgba(255, 0, 64, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
  }
}

@keyframes blink {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

@-webkit-keyframes blink {
  from,
  to {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* guest message menu tab blink end*/

/* image gallery chat with us start jenil */
.no-result-label {
  display: -ms-grid;
  display: grid;
  height: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

/* gallery-start */
.guest-chat-gallery-view {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 15px 1fr;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.guest-chat-gallery-view .image-3x3 {
  width: auto;
  height: 100px;
  margin: auto;
}

/* .guest-chat-gallery-view .image-3x3 img {
object-fit: cover;
} */

.guest-chat-gallery-view .video-3x3,
.guest-chat-gallery-view .docs-3x3 {
  width: auto;
  height: 100px;
  margin: auto;
}

.docs-3x3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.gallery-contents {
  height: 380px;
  overflow: auto;
}

.docs-3x3 img {
  width: 30px !important;
}

.audio-3x3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 10px;
}

.audio-3x3 audio {
  height: 40px;
  display: block;
  width: 100%;
}

/* image gallery chat with us end jenil */

/* for number input hide aerrow srushti 12-5-23 */
input.number-input::-webkit-outer-spin-button,
input.number-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.chat-container audio {
  height: 30px;
}

/* tab-bar-start */
.fixed-tab-bar .nav-tabs {
  border-bottom: 1px solid var(--dark);
}

.fixed-tab-bar .nav-link-tab {
  border: 1px solid var(--dark) !important;
  border-bottom: 0px !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  font-size: 12px;
  color: var(--dark) !important;
  padding: 4px 10px;
  margin-bottom: 0 !important;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.fixed-tab-bar .nav-item {
  padding: 0px 8px 0px 0px;
}

.fixed-tab-bar .nav-link-tab.active {
  color: var(--white) !important;
  background-color: var(--dark);
  border-color: var(--dark) !important;
}

.guest-chat-gallery-mode .no-result-label {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark);
}

/* tab-bar-end */

.uploadedby-label {
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  width: 100%;
}

/* srushti */
.selfverification-img img {
  height: 50%;
  width: 50%;
  margin-left: 90px;
}

.pwa-popup {
  position: fixed;
  bottom: 5px;
  left: 0px;
  right: 0px;
  margin: 0px auto;
  width: 97%;
  background: var(--lightdarkgray);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 4px;
  border-bottom: 3px solid #f37021;
}

.pwa-popup .pwa-icons {
  font-size: 18px;
  color: var(--dark);
}

.pwa-popup .pwa-text {
  color: var(--dark);
}

.allImages.location-transform img {
  width: 100%;
  height: 400px;
  overflow: auto !important;
  -o-object-fit: contain;
  object-fit: contain;
}

.allImages.location-transform {
  width: 100%;
  overflow: auto;
}

/*  */
.chat-bottom-send .rs-loader-sm .rs-loader-spin::before,
.chat-bottom-send .rs-loader-sm .rs-loader-spin::after {
  width: 14px;
  height: 14px;
  top: 5px;
  left: 2px;
}

/*  */
.play-icon-content {
  position: relative;
  width: 100%;
  height: 100%;
}

.play-icon {
  background-color: var(--white);
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 50px !important;
  color: var(--btn-lightblue);
  display: block !important;
}

.ratingTagGrid .task-tag-custom-control {
  width: auto;
  overflow: hidden;
}

.lost-desc {
  color: var(--gray);
  font-size: 12px;
  max-width: 400px;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.filterdot {
  position: absolute;
  top: 3px;
  right: 3px;
  /* z-index: ; */
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--red);
}

.list-label {
  border-radius: 4px;
  color: var(--white);
  font-size: 10px;
  padding: 4px 6px;
  width: 55px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.5px;
  margin-bottom: 0px;
}

.lostat {
  font-weight: 600;
}

.treee-label-text {
  /* cursor: pointer; */
  background-color: var(--white);
  text-align: center;
  height: 30px;
  -webkit-box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.5);
  box-shadow: 3px 3px 12px rgba(155, 155, 155, 0.5);
  font-weight: 600;
  font-size: 10px;
  border-radius: 1.5rem;
  margin-right: 0px;
  margin-bottom: 5px;
}

/* chat-ui */
.react-emojis-block {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  gap: 3px;
  padding: 0px 5px;
  border-radius: 15px;
  font-size: 12px;
  cursor: pointer;
}

.chat-item.right .react-emojis-block {
  background-color: #cae3fd;
  border: 1px solid var(--btn-lightblue);
}

.chat-item.left .react-emojis-block {
  background-color: #f5f7f9;
  border: 1px solid #bbb;
}

.chat-item.right .chat-message-reactions {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  float: right;
}

.new-block-container {
  border-radius: 12px;
  margin-left: auto;
  padding: 10px 10px 18px 10px;
  position: relative;
  width: 100%;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: 100px;
  margin-top: 10px;
  margin-bottom: 0px;
}

.new-block-container .chat-text {
  background-color: transparent !important;
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  padding: 0px !important;
  margin: 0px auto !important;
  max-width: 100%;
  width: 100%;
  min-width: 100%;
}

.chat-item.left .new-block-container.corner-border {
  border-top-left-radius: 0px !important;
}

.chat-item.left .new-block-container {
  background-color: #ffffff;
  -webkit-box-shadow: 1px 1px 1px 0px var(--header-color);
  box-shadow: 1px 1px 1px 0px var(--header-color);
  margin-right: auto;
  margin-left: inherit;
}

.chat-item.right .new-block-container.corner-border {
  border-top-right-radius: 0px !important;
}

.chat-item.right .new-block-container {
  /* background-color: #3498ff40; */
  background-color: #bedfff;
  -webkit-box-shadow: 0px 1px 1px 0px #008eff4d;
  box-shadow: 0px 1px 1px 0px #008eff4d;
}

.chat-item.left .new-block-chat.chat-text.important:before,
.chat-item.left .new-block-chat.chat-text.urgent:before {
  right: -14px;
}

.chat-item.right .new-block-chat.chat-text.important:before,
.chat-item.right .new-block-chat.chat-text.urgent:before {
  left: -14px;
}

.new-block-file {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.new-block-file .chat-img {
  padding: 0px !important;
  position: relative;
  width: 125px;
  height: 125px;
  display: block;
  overflow: hidden;
  max-width: unset !important;
}

.new-block-container .chat-file audio {
  width: 100%;
}

.reaction-emoji-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 5px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.react-emojis {
  position: relative;
  top: -2px;
}

.new-block-file .chat-file {
  width: 100%;
}

.custom-control-label .text-size {
  font-size: 14px;
  font-weight: 500;
}

.reaction-emoji {
  font-size: 16px;
}

.gap-1 {
  gap: 5px;
}

.footer a.active::after {
  content: "";
  border-top: 4px solid white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 70%;
  margin: 0 auto;
}

.multiple-filter-box {
  border-top: 1px solid var(--input-border);
  padding: 10px 12px 5px;
}

.multiple-filter-box .checkbox {
  padding-left: 20px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.select-all-text {
  padding-left: 5px;
  margin-top: -2px;
}

.multiple-filter-box .checkbox .checkmark {
  border: 1px solid var(--input-border);
  width: 16px;
  height: 16px;
}

.w-20 {
  width: 20%;
}

.w-80 {
  width: 80%;
}

.w-75 {
  width: 75%;
}

.rs-picker-check-menu-items
  .rs-check-item.rs-checkbox-checked
  .rs-checkbox-checker
  > label {
  font-weight: 400;
}

.rs-check-item .rs-checkbox-checker > label {
  font-size: 14px;
}

.select-box-check-box {
  background-color: #3498ff;
  border: 1px solid #3498ff !important;
}

.checkbox .select-box-check-box.checkmark:after {
  border-color: var(--white);
  height: 9px;
  width: 6px;
}

.rs-picker-check-menu.selectall-menu-checkpicker {
  height: auto !important;
  max-height: 395px !important;
}
.rs-picker-check-menu.selectall-menu-checkpicker .rs-picker-check-menu {
  height: auto !important;
  max-height: 280px !important;
}

.home-screen-modal {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  height: 100%;
  margin: 0 auto;
}

.home-screen-modal.rs-modal .rs-modal-dialog {
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
}

.home-screen-modal .btn-yes-no {
  padding: 5px !important;
}

/* menu-picker start */
.folded.rs-picker-menu-group {
  border: 0 !important;
}
.rs-picker-select-menu-items {
  width: 92%;
  margin: 0 auto;
}
.rs-picker-menu-group-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--dark) !important;
}
/* menu-picker end */

/* start modal-center */
.rs-modal-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.rs-modal {
  margin: 0 auto !important;
  max-height: calc(100% - 10px);
}
/* end modal-center */

/* start chatbot */
.body-content.chat-box {
  background-image: url(../images/chat-img.png);
  background-size: auto 100%;
}
.body-content.chat-box {
  background-color: rgb(242 247 252);
}
.body-content.chat-box.chatwith-bottom-btn {
  max-height: calc(100% - 170px);
}
.body-content.chat-box.chatwithout-bottom-btn {
  max-height: calc(100% - 125px);
}
.select-radio input {
  display: none;
}
.select-radio input:checked + label {
  color: var(--gray);
  background-color: var(--toastify-spinner-color-empty-area);
}
.chat-radio-btn {
  /* box-shadow: 2px 1px 3px 0px #cdcdcd; */
  -webkit-box-shadow: 2px 1px 3px -1px #dee2e6;
  box-shadow: 2px 1px 3px -1px #dee2e6;
  background-color: #fff;
  /* background-color: #edf0f3; */
  color: var(--primary);
  margin-bottom: 0;
  padding: 8px;
  font-size: 13px;
  width: 100%;
  text-align: center;
  border-radius: 4px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 10px;
}
.select-radio {
  display: -ms-grid;
  display: grid;
  gap: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
}

.checkbox-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-group label {
  position: relative;
  cursor: pointer;
}

.checkbox-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #0072ed;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkbox-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 7px;
  width: 6px;
  height: 14px;
  border: solid #4a90ea;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.auto-width {
  width: 100%;
  max-width: 80px;
  min-width: -webkit-fit-content;
  min-width: -moz-fit-content;
  min-width: fit-content;
  /* margin-bottom: 10px; */
}

.chat-container .chat-text {
  width: unset;
  max-width: unset;
  min-width: unset;
  margin-top: 5px;
}
.chat-item.right .auto-width {
  margin-left: auto;
}

.forward-content {
  background-color: #cbe6ff;
  border: 1px solid #c1e1ff;
  border-left: 2px solid #007bff;
  padding: 10px;
  margin-bottom: 5px;
  border-radius: 8px;
}

.chat-bot .chat-date {
  background-color: var(--lightgray-400);
  -webkit-box-shadow: 2px 1px 3px -1px #dee2e6;
  box-shadow: 2px 1px 3px -1px #dee2e6;
}
.chat-top-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--dark);
}
.-top-25 {
  top: -25px;
}
.bottom-menu-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
  font-weight: 500;
  color: #0072ec;
  padding: 5px;
  background-color: var(--white);
  border-top: 1px solid var(--btn-primary-transparent);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin: 0;
  width: 100%;
}
.bottom-menu .chat-text {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.chat-select-box-group [type="radio"]:checked,
.chat-select-box-group [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.chat-select-box-group [type="radio"]:checked + label,
.chat-select-box-group [type="radio"]:not(:checked) + label {
  position: relative;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: var(--dark);
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  margin-bottom: 5px;
}
.chat-select-box-group [type="radio"]:checked + label:before,
.chat-select-box-group [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #1675e0;
  border-radius: 100%;
  background: #fff;
}
.chat-select-box-group [type="radio"]:checked + label:after,
.chat-select-box-group [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #1675e0;
  position: absolute;
  top: 3px;
  right: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.chat-select-box-group [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.chat-select-box-group [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.chat-select-box-container
  .rs-modal-header
  .rs-modal-header-close.rs-btn-close {
  right: 5px;
  top: 5px;
  background-color: var(--btn-danger) !important;
  color: var(--white);
}
.chat-select-box-container .rs-btn-close .rs-icon {
  background-color: var(--btn-danger);
}
.tab-btn {
  width: 100%;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 500;
}
.send-btn {
  width: 95%;
  background-color: var(--primary);
  color: var(--white);
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}
.max-height-400 {
  max-height: 400px;
}
.date-picker-edit-input .rs-btn-default {
  padding: 8px !important;
  height: 35px !important;
  line-height: 35px !important;
  border-radius: 4px !important;
  /* width: 60%; */
}
.date-picker-edit-input .rs-picker-toggle-value {
  font-size: 12px;
}
.date-picker-edit-input input {
  padding: 10px !important;
}
.date-picker-edit-input
  .rs-picker-date.rs-picker-default
  .rs-picker-toggle-caret.rs-icon {
  top: 7px !important;
}
.date-picker-edit-input .rs-picker {
  height: 35px !important;
}
.sign-edit-box canvas {
  width: 100% !important;
  height: 200px !important;
}
.sign-edit-box {
  margin-top: 5px;
  margin-bottom: 2px;
  background-color: #fafafa !important;
  border: 1px solid var(--input-border);
  width: 100% !important;
  height: 200px !important;
}
.sign-edit-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}
.clear-sign-text {
  cursor: pointer;
  background-color: var(--btn-danger-transparent);
  border: 1px solid var(--btn-danger);
  border-radius: 20px;
  margin-top: 10px;
  padding: 2px 4px;
  width: 100%;
  max-width: 80px;
  margin-left: auto;
  text-align: center;
  font-size: 10px;
}
.chat-bot .chat-bottom-input-field {
  padding: 5px 40px 5px 10px;
}
.select-time-range {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
}
.chat-bot .chat-container .chat-text {
  padding: 10px 10px 18px 10px;
}
/* end chatbot */
.chat-bot-bottom-button {
  position: absolute;
  top: -45px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  left: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  border-bottom: 1px solid var(--input-border);
  border-top: 1px solid var(--input-border);
  padding: 10px;
}

.chat-bot-bottom-button-inner button {
  font-size: 12px;
  font-weight: 300;
  color: var(--white);
  background-color: transparent;
  position: relative;
  border: 1px solid var(--darkblue-100);
  padding: 2px 10px;
  border-radius: 20px;
  background-color: var(--darkblue-100);
}

.default-img-icon {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--rs-bg-backdrop);
  color: var(--white);
  font-size: 10px;
  font-weight: 500;
  border: 1px solid var(--input-border);
}

.chat-profile-img-div {
  height: 25px;
  width: 25px;
  border: 1px solid var(--input-border);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.chat-profile-img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

.date-days-or-text {
  text-align: center;
  width: 100%;
  display: block;
  position: relative;
}

.date-days-or-text:before,
.date-days-or-text::after {
  content: "";
  position: absolute;
  height: 1px;
  background-color: var(--input-border-hover);
  top: 10px;
}
.date-days-or-text:before {
  left: 0;
  width: 45%;
}
.date-days-or-text::after {
  right: 0;
  width: 45%;
}

.product-add-box-inner {
  border: 1px solid var(--gray-100);
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 4px;
}
.chat-bot-bottom-button-inner {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.main-selectpicker {
  position: relative;
}
.main-selectpicker .rs-picker-select-menu {
  top: 50px !important;
}
.date-picker {
  position: relative;
}
.add-field-btn-paymentmethod {
  border: 1px dotted var(--lightgray);
  border-radius: 8px;
  width: 100%;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.add-field-btn-paymentmethod label {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.add-field-btn-paymentmethod i {
  font-size: 44px;
  color: var(--lightgray);
}
.show-selfie-image {
  height: 200px;
  width: 100%;
  border: 1px dotted var(--lightgray);
  border-radius: 8px;
}
.show-selfie-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  padding: 5px;
}
.extend-stay-add-form .form-group {
  margin-bottom: 0 !important;
}
.reatake-image-icon {
  position: absolute;
  right: -5px;
  bottom: -5px;
  background-color: #deeaf4;
  padding: 5px;
  border-radius: 4px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.reatake-image-icon i {
  color: var(--dark);
  font-size: 18px;
}
.card-icon-scan i {
  font-size: 16px;
  background-color: #deeaf4;
  padding: 3px;
  border: 1px solid var(--btn-primary-transparent);
  border-radius: 5px;
}
.card-icon-scan {
  position: absolute;
  top: -35px;
  right: 15px;
  margin: 0 !important;
}
.card-icon-scan label {
  margin: 0 !important;
  display: block;
}
.extend-stay-add-form input.form-control {
  min-height: 40px !important;
  border-radius: 8px !important;
  font-size: 12px !important;
}
.disable-camera-label {
  opacity: 0.5;
}

.loyalty-sec-slider {
  /* display: grid;
grid-template-columns: 30% 65%; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background: var(--white);
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 10px -5px var(--gray);
  box-shadow: 0px 0px 10px -5px var(--gray);
  padding: 10px;
  margin: 5px;
  margin-bottom: 15px;
  height: 145px;
}
.carousel-item .loyalty-sec-slider {
  /* -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center; */
  height: 145px;
}

.loyalty-details .loyalty-top-btn {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.loyalty-sec-slider figure {
  margin: 0px;
  width: 100%;
  max-width: 100px;
  min-width: 100px;
  max-height: 100px;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  background-color: var(--gray-100);
}

.loyalty-sec-slider figure img {
  -o-object-fit: scale-down;
  object-fit: scale-down;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: block;
}

.loyalty-desc {
  display: block;
  overflow: hidden;
  -webkit-line-clamp: 3;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 1.4;
  font-size: 12px;
  /* min-height: 50px; */
}

.loyalty-details h4 {
  font-size: 13px;
  font-weight: 600;
  display: block;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  line-height: 1.4;
  max-height: 35px;
  margin-bottom: 5px;
}

.hide-arrows .carousel-control-prev,
.hide-arrows .carousel-control-next {
  display: none;
}

.loyalty-details {
  display: block;
  margin: 0;
  margin-left: 0px;
  height: 100%;
  position: relative;
}

.notification-title {
  display: block;
  font-size: 16px;
  font-weight: 500;
}

/* add JENIL 05/08/2023 5:44 PM */
.opacity-7 {
  opacity: 0.7;
}

.loyalty-desc-block {
  position: relative;
  /* min-height: 50px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.loyalty-read-btn {
  position: absolute;
  bottom: -1px;
  right: 0;
  background-color: var(--white);
  padding: 0px 0px 0px 5px;
}

.white-space-pre {
  white-space: pre-wrap;
}

.ellipsis-header {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: pre-wrap;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  width: 95%;
}

.dnd-on {
  -webkit-box-shadow: inset 0 0 0 2px rgb(255 15 15 / 100%),
    rgba(100, 100, 111, 0.2) 0px 7px 10px 2px !important;
  box-shadow: inset 0 0 0 2px rgb(255 15 15 / 100%),
    rgba(100, 100, 111, 0.2) 0px 7px 10px 2px !important;
  /* animation: shake 2s infinite; */
  border-color: rgb(255 15 15) !important;
}
@-webkit-keyframes shake {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(255 15 15 / 100%), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 0 rgb(255 15 15 / 100%), 0 0 0 0 rgb(255 1 1);
  }

  20% {
    -webkit-box-shadow: 0 0 0 5px rgb(255 15 15 / 50%), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 5px rgb(255 15 15 / 50%), 0 0 0 0 rgb(255 1 1);
  }

  40% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(255 1 1);
  }

  60% {
    -webkit-box-shadow: 0 0 0 5px rgb(255 15 15 / 50%), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 5px rgb(255 15 15 / 50%), 0 0 0 0 rgb(255 1 1);
  }

  80% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 64, 0),
      0 0 0 10px rgba(255, 0, 64, 0);
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 64, 0),
      0 0 0 10px rgba(255, 0, 64, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
  }
}
@keyframes shake {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(255 15 15 / 100%), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 0 rgb(255 15 15 / 100%), 0 0 0 0 rgb(255 1 1);
  }

  20% {
    -webkit-box-shadow: 0 0 0 5px rgb(255 15 15 / 50%), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 5px rgb(255 15 15 / 50%), 0 0 0 0 rgb(255 1 1);
  }

  40% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 0 rgb(255 1 1);
  }

  60% {
    -webkit-box-shadow: 0 0 0 5px rgb(255 15 15 / 50%), 0 0 0 0 rgb(255 1 1);
    box-shadow: 0 0 0 5px rgb(255 15 15 / 50%), 0 0 0 0 rgb(255 1 1);
  }

  80% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 0, 64, 0),
      0 0 0 10px rgba(255, 0, 64, 0);
    box-shadow: 0 0 0 10px rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 0, 64, 0),
      0 0 0 10px rgba(255, 0, 64, 0);
    box-shadow: 0 0 0 0 rgba(255, 0, 64, 0), 0 0 0 10px rgba(255, 0, 64, 0);
  }
}

.hide-arrows .carousel-indicators [data-bs-target] {
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.btn-xs {
  padding: 8px !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  background-color: var(--lightblue) !important;
  min-width: 120px;
}

.hide-arrows .carousel-indicators {
  margin-bottom: 0px;
}

.propertyLayoutBox .card-stay {
  padding: 5px;
  height: 200px;
}

.propertyLayoutBox .card-stay-imgs {
  height: 100%;
}

.propertyLayoutBox .card-stay-imgs img {
  width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 10px;
}

.rs-panel-collapsible {
  overflow: visible;
}

.view-all-btn {
  font-size: 14px;
  font-weight: 500;
  background-color: var(--white);
  display: block;
  padding: 2px 8px;
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 10px -5px var(--gray);
  box-shadow: 0px 0px 10px -5px var(--gray);
  margin: 0px 5px 5px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.all-img-show {
  overflow: auto;
  height: 100vh;
}

.no-data-imgs-content {
  width: 300px;
  height: 300px;
  margin: 0 auto;
}
.no-data-imgs {
  width: 100%;
  height: 100%;
  -o-object-fit: scale-down;
  object-fit: scale-down;
}

/* jatin 10-08-2023 Notification count */

.add-badge {
  background-color: var(--orange);
  position: absolute;
  top: -8px;
  right: -5px;
  width: auto;
  height: 15px;
  border-radius: 8px;
  color: var(--white);
  font-size: 10px;
  padding: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  /* line-height: 14px; */
  -webkit-box-shadow: 0px 0px 3px 0px rgb(119 119 119);
  box-shadow: 0px 0px 3px 0px rgb(119 119 119);
}
.modal-title-dotted {
  width: 290px;
  display: block;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0 auto;
}
.input-group-text i {
  font-size: 16px;
}
.btn.loyalty-btn {
  font-size: 12px !important;
}

.notificationContent {
  gap: 10px;
  width: 78%;
  position: relative;
}

.area-btn {
  padding: 6px 10px !important;
}

.btn.py-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

button.bg-dark:focus {
  background-color: var(--dark) !important;
}

.content.nearby-container {
  height: calc(100vh - 145px);
  /* padding-bottom: 45px; */
}
.nearby-container h5 {
  font-size: 16px;
}
.nearby-title {
  font-size: 14px;
  margin-bottom: 10px;
  color: var(--dark);
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.nearby-title i {
  font-size: 16px;
}
.nearby-subtext {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 400;
}
/* .content.nearby-container iframe {
height: calc(100vh - 225px);
} */
.chat-item {
  padding: 0px 15px;
}
.msg-redirect-blink {
  position: relative;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.msg-redirect-blink:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #4165ad1a;
}
.gridBox.chat-print-grid {
  gap: 10px;
}
.chat-print-grid .checkout-img-box {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: unset;
}
.chat-print-grid .checkout-img-box .custom-control-label {
  padding: 5px !important;
}
.chat-print-grid .housekeeping-img {
  height: 100%;
  width: 100%;
  padding: 0px 10px;
}

.line-height {
  line-height: 1;
}
.announcmentContent {
  width: 100% !important;
}
.announcement-subtitle {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.announcement-subtext {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  font-size: 12px !important;
}
.view-attach-btn {
  border: 1px solid var(--primary);
  border-radius: 4px;
  text-align: center;
  font-size: 10px;
  color: var(--primary);
  padding: 2px 5px;
  font-weight: 500;
  position: absolute;
  bottom: 3px;
  right: 3px;
}
.one-row-ann {
  padding-bottom: 30px;
}

.announcmentContent .notification-img {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  border: 1px solid var(--input-border);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.announcmentContent .notification-img img {
  width: 100% !important;
  height: 100% !important;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  padding: 2px;
}

.text-truncate {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.w-50 {
  width: 50% !important;
}

.no-data-imgs-modal .no-data-imgs {
  width: 250px;
  height: 250px;
  -o-object-fit: scale-down;
  object-fit: scale-down;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.drawimage-buttons .btn {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px !important;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 5px;
  padding: 10px 15px !important;
  width: auto !important;
  font-weight: 400 !important;
}

.btn-org {
  background-color: var(--orange) !important;
}
.btn-blue {
  background-color: var(--blue) !important;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.sticky-btn {
  position: fixed;
  top: 40px;
  z-index: 9999;
  background-color: var(--white);
  padding: 15px;
}
.pdf-modal .rs-modal-body {
  padding-top: 0;
}
.btn-arrow {
  background-color: var(--dark);
  color: var(--white);
  font-size: 12px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 2px;
  border-radius: 50%;
}
.btn-arrow span {
  font-size: 16px;
}
.pdf-page-count {
  font-size: 12px;
  font-weight: 600;
  color: var(--dark);
}
button.btn-arrow[disabled] {
  background-color: var(--btn-primary-transparent);
  color: var(--dark);
}
.render-signature-canvas {
  position: absolute;
  z-index: 9;
  /* top: 68px; */
}
.react-pdf__Document {
  position: relative;
  z-index: 9;
  height: 100%;
  /* margin-top: 68px; */
}
.pdf-loader {
  height: 200px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  top: 30px;
}
.regcard-render-signature-canvas .react-transform-wrapper {
  max-width: 100%;
  max-height: calc(100vh - 200px);
}
.render-regcard-pdf,
.regcard-pdf-drawimage-buttons,
.regcard-render-signature-canvas {
  height: 100%;
  max-height: calc(100vh - 220px);
}
.redirection-label {
  width: 100%;
  display: block;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
}
.w-100 {
  width: 100% !important;
}

.accordion-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: var(--white);
}
.rotate-180 {
  -webkit-transform: rotate(180deg) !important;
  -ms-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}
.other-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.nearby-container {
  border-radius: 5px;
  background-color: var(--white);
  -webkit-box-shadow: rgba(72, 72, 119, 0.2) 0px 7px 7px 0px;
  box-shadow: rgba(72, 72, 119, 0.2) 0px 7px 7px 0px;
  padding: 15px 15px;
  margin: 15px 0;
}

.star-data {
  margin: 0;
  text-align: center;
}

.distance-text {
  text-align: center;
  line-height: 1.4;
}

.pdf-previous-button {
  position: absolute;
  display: block;
  top: 50%;
  bottom: 50%;
  left: 10px;
  right: auto;
  margin: auto;
  width: 26px;
  height: 26px;
  z-index: 10;
}

.pdf-next-button {
  position: absolute;
  display: block;
  top: 50%;
  bottom: 50%;
  right: 10px;
  left: auto;
  margin: auto;
  width: 26px;
  height: 26px;
  z-index: 10;
}

.pdf-previous-button .btn-arrow,
.pdf-next-button .btn-arrow {
  padding: 5px;
}

.success-back-btn.btn {
  border-radius: 30px !important;
  padding: 8px !important;
  font-size: 14px !important;
  background-color: var(--orange) !important;
  -webkit-box-shadow: 2px 2px 4px 2px var(--lightgray-700);
  box-shadow: 2px 2px 4px 2px var(--lightgray-700);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border: 2px solid var(--orange) !important;
}
.success-back-btn.btn:hover {
  background-color: #fff7f1 !important;
  color: var(--orange) !important;
}
.btn.pdf-btn {
  padding: 10px 5px !important;
  font-size: 14px !important;
  gap: 5px !important;
}
.btn.pdf-draw {
  background-color: var(--orange) !important;
}
.btn.pdf-clear {
  background-color: var(--red-100) !important;
}
/* add-item */
.search-input-icon {
  position: relative;
  margin: 15px 0px;
}
.search-input-icon input {
  display: block;
  width: 100%;
  border: 1px solid var(--input-border);
  padding: 8px 10px;
  border-radius: 4px;
  font-size: 12px;
  color: var(--dark);
}
.search-input-icon i {
  position: absolute;
  right: 6px;
  top: 8px;
  font-size: 20px;
  color: var(--dark);
}
.search-input-icon input:focus {
  outline: none;
}
.single-category-grid-list {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  height: 100%;
}
.product-name {
  /* height: 22px; */
  max-width: 80px;
  padding: 0;
  overflow: hidden;
  position: relative;
  text-align: center;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.category-image {
  height: 100px;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  padding: 2px;
  cursor: pointer;
}
.category-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
}
.product-checkbox .custom-control-label-checkbox,
.product-issue-tags {
  font-size: 12px;
  color: var(--dark);
  font-weight: 500;
  background-color: var(--header-color);
  padding: 4px 8px;
  border-radius: 20px;
  cursor: pointer;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.product-checkbox.fill-checkbox .custom-control-label-checkbox,
.product-issue-tags {
  background-color: #2f70af;
  color: var(--white);
}
.search-input-icon input::-webkit-input-placeholder {
  color: var(--input);
}
.search-input-icon input::-moz-placeholder {
  color: var(--input);
}
.search-input-icon input:-ms-input-placeholder {
  color: var(--input);
}
.search-input-icon input::-ms-input-placeholder {
  color: var(--input);
}
.search-input-icon input::placeholder {
  color: var(--input);
}
.single-product-image {
  width: 100px;
  height: 100px;
  margin: 0 auto;
}
.single-product-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.single-product {
  border-radius: 4px;
  -webkit-box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 4px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 2px 4px 0px;
  border: 1px solid var(--input-border);
  padding: 10px;
}
.single-product-issue {
  max-height: 100px;
  overflow: auto;
}
.single-product.request-product {
  width: fit-content;
}

.form-group.margin-bottom-0 {
  margin-bottom: 0px !important;
}
.single-dot .carousel-indicators {
  display: none;
}

.date-picker .rs-stack-item button.rs-btn {
  padding: 4px 10px !important;
  border-radius: 4px !important;
  background-color: var(--dark);
}
.shadow-box-content {
  border-radius: 8px;
  box-shadow: 5px 7px 26px -5px #cdd4e7;
  border: none;
  background-color: var(--white);
  width: 95%;
  margin: 15px auto;
}
.notification-sub-title {
  font-size: 14px !important;
  font-weight: 400 !important;
}